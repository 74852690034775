import "../App.css";
import React, { useState } from "react";
import wewadlogo from "../images/wewadlogo.png";
import newlogo from "../images/logooo.png";

import menu from "../images/newmenu.png";
import { Link } from "react-router-dom";

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuItemClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <div>
      <nav>
        <nav className="navbar fixed-top navbar-expand-lg ps-md-4  pe-2">
          <div className="container-fluid">
            <a className="navbar-brand" href="/">
              <img className="logo  navlogo " src={newlogo} alt="" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              onClick={handleMenuToggle}
              aria-expanded={isMenuOpen}
              aria-label="Toggle navigation"
            >
              <span
                className="navba-toggler-icon"
               
              ><i class="fa-solid fa-bars"></i></span>
            </button>
            <div
              className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`}
              id="navbarNav"
            >
              <ul className="navbar-nav ms-auto ">
                <li className="nav-item">
                  <a
                    className="nav-link pt-4 active ps-4"
                    aria-current="page"
                    href="/"
                    onClick={handleMenuItemClick}
                  >
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link pt-4 ps-4"
                    href="/about"
                    onClick={handleMenuItemClick}
                  >
                    About Us
                  </a>
                </li>
                <li className="nav-item nav-link ">
                  <div class="dropdown">
                    <button
                      class="btn dropdown-toggle mediabtn"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Media
                    </button>
                    <ul class="dropdown-menu">
                      
                      <li className="">
                        <a class=" dropdown-item  " href="/photogallery">
                          Photo Gallery{" "}
                        </a>
                      </li>
                      <li className="">
                        <a class="dropdown-item" href="/videogallery">
                          Video Gallery{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link pt-4 ps-4"
                    href="/blog"
                    onClick={handleMenuItemClick}
                  >
                    Blog
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link pt-4 ps-4"
                    href="/contact"
                    onClick={handleMenuItemClick}
                  >
                    Contact
                  </a>
                </li>
                <li className="nav-item pt-3 ps-2">
                  <a href="/donate">
                    <button className="joinusbtn">
                      <span className="pe-2">Donate Now</span>{" "}
                    </button>
                    </a>
                </li>
                {/* Additional nav items here */}
              </ul>
            </div>
          </div>
        </nav>
      </nav>
    </div>
  );
}

export default Navbar;
