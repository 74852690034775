import React from "react";
import "animate.css";
// import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"; // Import Animate.css styles
// import { Link } from "react-router-dom";
import homesection4img1 from "../images/homesection4img1.jfif";

import testimonialimg1 from "../images/testimonialimg1.png";
import testimonialimg2 from "../images/testimonialimg2.png";
import testimonialimg3 from "../images/testimonialimg3.png";
import testimonialimg4 from "../images/testimonialimg4.png";
import testimonialimg5 from "../images/testimonialimg5.png";
import testimonialimg6 from "../images/testimonialimg6.png";
import testimonialimg7 from "../images/testimonialimg7.png";
import testimonialimg8 from "../images/testimonialimg8.png";
import testimonialimg9 from "../images/testimonialimg9.png";
import testimonialbackgroundimg from "../images/testimonialbackgroundimg.jfif";
import blogpageimg2 from "../images/blogpageimg2.jpg";
import blogpageimg3 from "../images/blogpageimg3.jpg";
import blogpageimg4 from "../images/blogpageimg4.jpg";
import blogpageimg5 from "../images/blogpageimg5.jpg";
import blogpageimg6 from "../images/blogpageimg6.jpg";

function BlogPage2() {
  return (
    <div className="p-md-5 p-3">
      <div className="wh">
        <section className="aboutsection1">
          <div className="homesection3heading pb-2">
            The Art of Giving: <br />
            More Than Just a Gesture{" "}
          </div>
          <div className="blogpagepara text-center">
            In a world that often emphasizes the importance of acquiring and
            achieving, the act of giving stands out as a powerful
            counterbalance. Giving, in its truest form, is not merely an action
            but an art that enriches both the giver and the receiver. Let’s
            delve into the essence of giving and explore its transformative
            power
          </div>
          <div className="py-3">
            <img src={blogpageimg2} alt="" className="mobilewidth"/>
          </div>
          <div className="blogpagesubheadingsmall pt-4 pb-2">
            1. The Joy of Giving
          </div>
          <div className="blogpagepara pt-2">
            There’s an unparalleled joy that comes from giving without expecting
            anything in return. This joy transcends material possessions; it’s
            the joy of making a difference in someone’s life, however small.
            Whether it’s a simple act of kindness, a helping hand, or a generous
            gift, giving fills our hearts with warmth and satisfaction.
          </div>
          <div className="blogpagesubheadingsmall pt-4 pb-2">
            2. Building Stronger Connections{" "}
          </div>
          <div className="blogpagepara pt-2">
            Giving fosters deeper connections and strengthens relationships.
            When we give, we open ourselves up to vulnerability, showing others
            that we care and value them. This fosters trust and builds bonds
            that are resilient and enduring. Whether it’s within families,
            friendships, or communities, giving creates a sense of unity and
            shared purpose.
          </div>{" "}
          <div className="blogpagesubheadingsmall pt-4 pb-2">
            3. Personal Growth and Fulfillment{" "}
          </div>
          <div className="blogpagepara pt-2">
            The act of giving is a journey of personal growth and
            self-discovery. It cultivates empathy, compassion, and gratitude,
            helping us to understand and appreciate the experiences of others.
            By stepping outside of our own needs and desires, we gain a broader
            perspective on life and find deeper meaning and fulfillment.
          </div>{" "}
          <div className="blogpagesubheadingsmall pt-4 pb-2">
            4. Creating a Ripple Effect
          </div>
          <div className="blogpagepara pt-2">
            Giving has the power to create a ripple effect, inspiring others to
            pay it forward. When we give generously and genuinely, we inspire
            kindness and generosity in others, creating a cycle of positivity
            that extends far beyond our initial act. It’s a beautiful testament
            to the interconnectedness of humanity and the potential for
            collective change.
          </div>{" "}
          <div className="blogpagesubheadingsmall pt-4 pb-2">
            5. The Value of Intentional Giving{" "}
          </div>
          <div className="blogpagepara pt-2">
            While spontaneous acts of giving are wonderful, intentional giving
            adds depth and purpose to our actions. It involves thoughtful
            consideration of the needs and desires of others, ensuring that our
            gifts resonate on a deeper level. Whether it’s donating to a cause
            close to our hearts, volunteering our time, or offering emotional
            support, intentional giving allows us to make a meaningful impact.
          </div>{" "}
          <div className="blogpagesubheadingsmall pt-4 pb-2">Conclusion </div>
          <div className="blogpagepara pt-2">
            In conclusion, the art of giving is a transformative force that
            enriches our lives in countless ways. It transcends mere
            transactions and touches the very core of our humanity. As we
            embrace the joy of giving, we not only uplift others but also
            nurture our souls, fostering a world filled with compassion,
            connection, and collective growth.
            <br />
            So, let’s celebrate the power of giving and embrace it as an
            integral part of our lives. After all, the true essence of life lies
            not in what we acquire but in what we give.
          </div>
        </section>
      </div>
      <section className="homesection6 p-md-5">
        <div className="homesection3heading">What People Say About Us</div>
        <div className="homesection3subheading">
          Hear from those who have benefited from our programs
        </div>
        <div className="homesection6grid pt-3">
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg1} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Chidinma Okeke</div>
                <div className="testimoniallocation pt-1">Lagos, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD has been a beacon of hope for me. As a single mother, the
              vocational training I received has enabled me to start my own
              small business. Now, I can provide for my family and secure a
              brighter future for my children. Thank you, WEWAD!
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg2} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Tunde Adebayo</div>
                <div className="testimoniallocation pt-1">Ibadan, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              Living with a disability was challenging, but WEWAD's support has
              made a tremendous difference. The mentorship and resources
              provided have empowered me to live independently and pursue my
              career goals. I am forever grateful.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg3} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Ngozi Okoro</div>
                <div className="testimoniallocation pt-1">Rivers, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              Thanks to WEWAD, I have found a community that supports and
              uplifts me. The programs have not only provided me with practical
              skills but also boosted my confidence. I am now running a
              successful catering business and giving back to my community.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg4} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Emeka Nwosu</div>
                <div className="testimoniallocation pt-1">Enugu, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD's empowerment programs have transformed my life. I have
              learned new skills and met wonderful people who inspire me daily.
              With WEWAD's help, I have started a tailoring business, and I am
              proud of what I have achieved.!
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg5} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Kemi Adesina</div>
                <div className="testimoniallocation pt-1">
                  Port Harcourt, Nigeria.
                </div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              The inclusive community at WEWAD has been a source of strength for
              me. The resources and guidance I received have helped me build a
              successful career in technology. WEWAD truly changes lives, and I
              am a testament to that.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg6} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Femi Adeoye</div>
                <div className="testimoniallocation pt-1">
                  Benin City, Nigeria.
                </div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD's initiatives are life-changing. The vocational training I
              received has opened up new opportunities for me, and I am now
              working in a field I love. WEWAD’s commitment to empowerment is
              evident in everything they do.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg7} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Aisha Bello</div>
                <div className="testimoniallocation pt-1">Abuja, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              Joining WEWAD was one of the best decisions I’ve ever made. The
              community is incredibly supportive, and the workshops have given
              me valuable skills and confidence. I now feel equipped to face any
              challenge that comes my way.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg8} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Yemi Oladipo</div>
                <div className="testimoniallocation pt-1">Jos, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              The mentorship and support from WEWAD have been invaluable. I have
              gained skills that have made me more competitive in the job
              market. WEWAD’s focus on inclusivity and empowerment is making a
              real difference in people’s lives.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg9} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Funke Afolayan</div>
                <div className="testimoniallocation pt-1">Ilorin, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD has given me hope and a sense of purpose. The workshops and
              training sessions have equipped me with the tools I need to
              succeed. I am now confidently pursuing my passion for fashion
              design, and I owe it all to WEWAD.
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default BlogPage2;
