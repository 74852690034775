import React from "react";
import "../App.css";
// import 'animate.css';
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css"; // Import Animate.css styles
import wewadimg2 from "../images/wewadimg2.png";
import wewadimg4 from "../images/wewadimg4.png";
import wewadimg5 from "../images/wewadimg5.png";
import wewadimg6 from "../images/wewadimg6.png";
import wewadimage1 from "../images/wewadimage1.jpg";
import wewadimage2 from "../images/wewadimage2.jpg";
import wewadimage3 from "../images/wewadimage3.jpg";
import wewadimage4 from "../images/wewadimage4.jpg";
import wewadimage5 from "../images/wewadimage5.jpg";
import wewadimage6 from "../images/wewadimage6.jpg";
import wewadimage16 from "../images/wewadimage16.jpg";

import testimonialimg1 from "../images/testimonialimg1.png";
import testimonialimg2 from "../images/testimonialimg2.png";
import testimonialimg3 from "../images/testimonialimg3.png";
import testimonialimg4 from "../images/testimonialimg4.png";
import testimonialimg5 from "../images/testimonialimg5.png";
import testimonialimg6 from "../images/testimonialimg6.png";
import testimonialimg7 from "../images/testimonialimg7.png";
import testimonialimg8 from "../images/testimonialimg8.png";
import testimonialimg9 from "../images/testimonialimg9.png";
import testimonialbackgroundimg from "../images/testimonialbackgroundimg.jfif";
import { Link } from "react-router-dom";

function About() {
  return (
    <div>
      <section className="aboutsection1">
        <div className="homesection3heading pb-2">About Us</div>
        <div className="homesection3subheading">
          Learn more about WEWAD and our mission to empower women and
          individuals with disabilities.
        </div>
        <div className="pt-4">
          <img src={wewadimg2} className="mobilewidth px-3" />
        </div>
      </section>
      <section className="homesection2 p-md-5 p-4">
        <div className="homesection2dotpara ps-md-3">
          {" "}
          <span className="thick-dot"></span>
          <span className="ps-2">Our Story</span>
        </div>
        <div>
          <div className="homesection2grid2para pb-4">
            {" "}
            <span className="bluehomesection2grid2para">
              WEWAD (Women and Empowerment for Women and Disabled){" "}
            </span>
            was founded with a simple yet powerful vision: to create a world
            where every individual, regardless of gender, ability, age,
            background, tribe, or religion, is empowered to reach their full
            potential. Our journey began in Nigeria, where we recognized the
            need for an inclusive community that supports women and individuals
            with disabilities. Over the years, WEWAD has grown into a beacon of
            hope and empowerment, transforming countless lives through our
            programs and initiatives.
          </div>
          <div className="aboutsection2heading pt-4 pb-2">Core Values</div>
          <div className="aboutsection2subheading pb-3">
            At WEWAD, our core values guide everything we do. We are committed
            to:
          </div>
          <div className=" homesection2subgrid aboutsection2subgrid">
            <div className="homesection2subgridgroup">
              <div className="homesection2subgridheading pb-2">Inclusivity</div>
              <div className="homesection2subgridsubheading">
                Ensuring that everyone, regardless of their background or
                abilities, feels welcomed and supported.
              </div>
            </div>
            <div className="homesection2subgridgroup">
              <div className="homesection2subgridheading pb-2">Empowerment</div>
              <div className="homesection2subgridsubheading">
                Providing the tools and resources needed for individuals to
                thrive personally and professionally.
              </div>
            </div>
            <div className="homesection2subgridgroup">
              <div className="homesection2subgridheading pb-2">Community</div>
              <div className="homesection2subgridsubheading">
                Building a strong, supportive network where members can connect,
                share, and grow together.{" "}
              </div>
            </div>
            <div className="homesection2subgridgroup">
              <div className="homesection2subgridheading pb-2">Integrity</div>
              <div className="homesection2subgridsubheading">
                Conducting all our activities with transparency and honesty.{" "}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="aboutsection3 p-md-5 p-4">
        <div>
          <div className="homesection3heading aboutsection3heading text-start">
            {" "}
            Meet The Chief Executive
          </div>
          <div className="homesection3subheading text-start pt-4">
            Amope Phillips is a dedicated philanthropist and humanitarian with a
            deep passion for making a positive impact in people's lives. Born in
            Lagos State and raised in Eruwa, Oyo State, Nigeria, Amope has
            always been driven by her love for people and her commitment to
            empowering communities.
          </div>

          <div className="homesection3subheading text-start pt-2">
            Currently residing in America, Amope is the visionary founder of the
            Women Empowering Women And Disabled Foundation. Through this
            foundation, she has been instrumental in blessing countless lives
            across the globe, with a special focus on Nigeria. Her tireless
            efforts have made a significant difference, providing support,
            empowerment, and opportunities to those in need.
            <div className="pt-2">
              Be a part of Amope Phillips' mission to empower women and
              individuals with disabilities. Here’s how you can contribute:{" "}
            </div>
          </div>
          {/* <div className="aboutsection3group ">
            <div className="pt-5">
              <a href="https://docs.google.com/forms/d/1iNM6CzONKNap5DlIKIT9XyqsSKJt-ewTI4hmiU5m_kE/viewform?edit_requested=true">
                <button className="joinusbtn"> Volunteer with Us</button>{" "}
              </a>
            </div>
            <div className="pt-5">
            <a href="/donate">
              <button className="donatebtn"> Make a Donation</button>{" "}
              </a>            </div>
          </div> */}
        </div>
        <div>
          <img src={wewadimg4} />
        </div>
      </section>
      <section className="aboutsection4 p-md-5 p-4p-5">
        <div className="homesection3heading">Meet Our Team</div>
        <div className="homesection3subheading pt-2 pb-4">
          Our dedicated team is the heart of WEWAD. Comprised of passionate
          individuals from diverse backgrounds, <br /> our team works tirelessly
          to achieve our mission.
        </div>
        <div className="aboutsectiongrid4 ">
          <div>
            <img src={wewadimg5} className="mobilewidth  " />
            <div className="aboutsection2heading pt-3">
              Hon. Phillips Olubunmi Emmanuel
            </div>
            <div className="aboutsection2subheading pt-2">President</div>
          </div>
          <div>
            <div className="aboutsection4para text-start pt-3">
              Hon. Phillips Olubunmi Emmanuel is an excellent, industrious,
              compassionate and amiable leader. He was born in Yaba, Lagos
              State. He had his primary education at Anglican Primary School,
              Yana. He went to Lagos City College, Sabo Yaba for his Junior High
              School and proceeded to Iponri Grammar School, Iponri Lagos where
              he had his Senior Secondary School education.
            </div>{" "}
            <div className="aboutsection4para text-start pt-3">
              He has a B.Sc in Business Administration from Lagos State
              University, Ojo. Hon. Phillips Olubunmi Emmanuel worked with
              Zenith Bank in different departments ranging from Cash Teller
              Unit, Customer Care to Fund Transfer Unit and then as a
              Supervisor.
            </div>{" "}
            <div className="aboutsection4para text-start pt-3">
              He left Zenith Bank in 2004 to start his political career and also
              go into business Hon Phillips Olubunmi Emmanuel been an active
              member of the All Progressive Congress, his love for humanity
              especially for the people of his community contested as a
              Councillor in Ward F1 Surulere Local Government in 2017 and won.
            </div>{" "}
            <div className="aboutsection4para text-start pt-3">
              He was the Deputy Majority Leader and Chairman, Committee for
              Environment and Primary Healthcare Matters in Surulere Local
              Government. Having performed tremendously and excellently, he was
              nominated by the community for a second term as Councillor Ward F1
              Surulere Local Government in 2021 and he won.
            </div>{" "}
            <div className="aboutsection4para text-start pt-4">
              He is currently the Chairman, House Committee on education. He is
              also the Chairman/CEO of Phleeps Global Services. He is a member
              of the Navel Cadet Corps LASU Command. He is also a member of the
              Federal Road Safety Special Marshal, Isolo command. As a service
              to his community and his contribution to the growth of others, he
              was worn several awards.
            </div>{" "}
          </div>
        </div>
        <div className="pt-4">
          <div className="aboutsectiongrid4 ">
            <div>
              <img src={wewadimage2} className="mobilewidth  teamimage" />
              <div className="aboutsection2heading pt-3">
                Olayiwola Akinrinle
                <div className="aboutsection2subheading pt-2">
                  IT Infrastructure Specialist
                </div>
              </div>
            </div>
            <div>
              <div className="aboutsection4para text-start pt-3 aboutsection4paralargefont">
                I am Olayiwola Akinrinle an IT Infrastructure Specialist with
                over 6 years of experience in the Information Technology
                Infrastructure sector working with tier Companies like
                Microsoft, Tek-Expert, Foodconceptsplc Fidelity Bank, Premium
                Trust Bank.First degree in Electrical /Electronics
                Engineering,Post Graduate Degree in Computer Science also an
                associate degree in Business Analysis in Information Technology.
                Currently, I'm an IT Infrastructure Manager at Chert Systems
                Solutions has allowed me to manage employees at all levels
                including executives within and outside the organization. My
                expertise in project management, Infrastructure as a system
                analyst, Networking, and security Expert .
              </div>{" "}
              <div className="aboutsection4para text-start pt-4 aboutsection4paralargefont">
                Lagos, Nigeria
              </div>{" "}
            </div>
          </div>
        </div>
        <div className="pt-4">
          <div className="aboutsectiongrid4 ">
            <div>
              <img src={wewadimage3} className="mobilewidth  teamimage" />
              <div className="aboutsection2heading pt-3">
                Wusu Shakiru Ajiyon
                <div className="aboutsection2subheading pt-2">
                  MD CEO Wawo Multilnks ltd.
                </div>
              </div>
            </div>
            <div>
              <div className="aboutsection4para text-start pt-3 aboutsection4paralargefont">
                MD CEO Wawo Multilnks ltd. <br />
                Health and safety consultant
                <br />
                Certified HACCP EXPERT.
                <br />
                SAFETY LEAD AUDITOR. <br />
                LIFE COACH <br />
                BUSINESS CONTENT DEVELOPER <br />
                ENTREPRENEUR. <br />
                POLITICIAN AND PUBLIC ANALYST <br />
                COMMUNITY LEADER
              </div>{" "}
            </div>
          </div>
        </div>
        <div className="pt-4">
          <div className="aboutsectiongrid4 ">
            <div>
              <img
                src={wewadimage5}
                className="mobilewidth  teamimagebarbara"
              />
              <div className="aboutsection2heading pt-3">
                Barbara Faduola{" "}
                <div className="aboutsection2subheading pt-2">
                  Chief Executive Officer of Brooks and Health Care Ltd
                </div>
              </div>
            </div>
            <div>
              <div className="aboutsection4para text-start pt-3">
                Barbara Faduola is a dedicated and accomplished healthcare
                professional, currently serving as the Chief Executive Officer
                of Brooks and Health Care Ltd in the UK. With a robust
                foundation in nursing and a fervent commitment to advancing
                healthcare services, Barbara has cultivated a career that
                exemplifies her dedication to excellence in patient care and
                health education. Barbara's journey in healthcare began with her
                studies in Access to Nursing at Uxbridge College, where she
                developed a comprehensive understanding of nursing principles.
                She furthered her education by obtaining a degree in Nursing
                from the University of West London, graduating in 2006. Since
                qualifying as a registered general nurse, Barbara has amassed
                extensive experience across various healthcare settings,
                consistently showcasing her clinical expertise and compassionate
                approach to patient care.
                <br /> <br />
                Throughout her career, Barbara has made significant
                contributions to the NHS, where she ascended to the role of
                Senior Nurse. In this capacity, she not only provides
                high-quality care but also mentors and supports junior staff,
                reflecting her commitment to public health and her desire to
                enhance patient outcomes.
                <br /> <br />
                In pursuit of further specialization, Barbara studied Radiology
                at the University of Birmingham, expanding her knowledge and
                skills in diagnostic imaging. She later completed her Master’s
                degree at Cardiff University, deepening her understanding of
                advanced nursing practices and healthcare management.
                Recognizing the vital role of education in shaping the future of
                healthcare, Barbara graduated as a university lecturer from
                Hertfordshire University in 2023, underscoring her dedication to
                teaching and mentoring the next generation of healthcare
                professionals.
                <br />
                <br />
                Barbara's career progressed significantly when she took on
                managerial roles at prestigious institutions, including Harley
                Street and London Bridge Hospital. In these positions, she
                demonstrated exceptional leadership and operational management
                skills, overseeing clinical teams and ensuring the delivery of
                high-quality patient care. Her experience in these renowned
                healthcare environments further honed her expertise in
                healthcare management and patient-centered care.
                <br /> <br />
                In addition to her clinical and academic achievements, Barbara
                is a successful entrepreneur. She founded Brooks and Health Care
                Ltd in 2014, laying the groundwork for her vision of delivering
                high-quality healthcare services. After successfully registering
                with the Care Quality Commission (CQC) and other regulatory
                bodies, she officially launched the business in 2022. Under her
                leadership, Brooks and Health Care Ltd is committed to providing
                exceptional care and support to individuals in need, with a
                focus on personalized service and community well-being.
                <br /> <br />
                Balancing her responsibilities as a Senior Nurse in the NHS with
                her role as a business owner, Barbara exemplifies dedication and
                resilience. She is not only a skilled nurse and business leader
                but also a lifelong learner passionate about improving
                healthcare delivery and education. Her journey reflects an
                unwavering commitment to making a positive impact in the
                healthcare sector and inspiring others to pursue their dreams in
                nursing and beyond. Barbara is also pursuing a Level 5 Diploma
                in Management at the University of East London, further
                enhancing her leadership capabilities and strategic vision for
                the future of healthcare.
              </div>{" "}
            </div>
          </div>
        </div>

        <div className="pt-4">
          <div className="aboutsectiongrid4 ">
            <div>
              <img src={wewadimage6} className="mobilewidth teamimage " />
              <div className="aboutsection2heading pt-3">
                Oluwatoyin Phillips{" "}
                <div className="aboutsection2subheading pt-2">Banker </div>
              </div>
            </div>
            <div>
              <div className="aboutsection4para text-start pt-3 aboutsection4paralargefont">
                I'm Oluwatoyin Phillips a banker with over 16 years of
                experience in Retail Banking (Product Management, Recovery of
                delinquent loans, Bancassurance, Payments and Remittances and an
                analyst).
                <br />
                Obtained Higher National Diploma in Town and Regional Planning
                from the Moshood Abiola Polytechnic Abeokuta, Ogun State.
                Masters in Business Administration (Finance option) and
                Geography and Planning (BSc.) both from Lagos State University
                Ojo, Lagos State.
                <br />A graduate member of the Nigerian Institute of Management.
                A member of the Lions Club International.
              </div>{" "}
            </div>
          </div>
        </div>
        <div className="pt-4">
          <div className="aboutsectiongrid4 ">
            <div>
              <img src={wewadimage16} className="mobilewidth teamimage " />
              <div className="aboutsection2heading pt-3">
              Akinrinle Oluwatimilehin Micheal{" "}
                <div className="aboutsection2subheading pt-2"> Project Officer </div>
              </div>
            </div>
            <div>
              <div className="aboutsection4para text-start pt-3 aboutsection4paralargefont">
                I am Akinrinle Oluwatimilehin Micheal, a Project Officer with a
                foundation in architecture and experience across architecture,
                safety, and project management. <br /> <br /> I have contributed to impactful
                projects in the aviation and housing sectors through roles with
                FAAN and the Lagos State Development and Property Corporation,
                followed by a role as a Safety Officer at the Lagos State Safety
                Commission. My current role with Food Concept builds on this,
                where I manage and support various projects. <br /> <br /> I am committed to
                creating sustainable, safe, and innovative environments. Lagos,
                Nigeria.
              </div>{" "}
            </div>
          </div>
        </div>
        <div className="pt-4">
          <div className="aboutsectiongrid4 ">
            <div>
              <img src={wewadimage4} className="mobilewidth  teamimage" />
              <div className="aboutsection2heading pt-3">
                Dr. Abiodun Oluwalowo
                <div className="aboutsection2subheading pt-2">
                  Snr Product Engr
                </div>
              </div>
            </div>
            <div>
              <div className="aboutsection4para aboutsection4paralargefont text-start pt-3">
                Dr. Abiodun Oluwalowo
                <br />
                <br />
                Occupation: Snr Product Engr
                <br /> <br />
                Place of work : Lam Research
                <br /> <br />
                Location: San Francisco California, USA
              </div>{" "}
            </div>
          </div>
        </div>
        <div className="pt-4">
          <div className="aboutsectiongrid4 ">
            <div>
              <img src={wewadimage1} className="mobilewidth  teamimage" />
              <div className="aboutsection2heading pt-3">
                Okomayin Patrick Tunde
                <div className="aboutsection2subheading pt-2">
                  {" "}
                  Certified Monitoring and Evaluation officer
                </div>
              </div>
            </div>
            <div>
              <div className="aboutsection4para  aboutsection4paralargefont text-start pt-3">
                Graduate from University of Benin 2009
                <br /> <br />
                Certified Monitoring and Evaluation officer
                <br /> <br />
                B.sc in Economic and statistic
              </div>{" "}
            </div>
          </div>
        </div>

        {/* <div className="pt-4">
          <div className="aboutsectiongrid4 ">
            <div>
              <div className="aboutsection4para text-start pt-3">
                Adekunle Abraham is a multimedia personality with over two
                decades experience as a graphics designer, over a decade
                experience as a media personnel, and over a decade experience as
                an internet marketing mogul and digital marketer.
              </div>{" "}
              <div className="aboutsection4para text-start pt-3">
                He has helped several businesses in branding and building their
                brands through different marketing strategies and methods. He
                leads a team of professionals at Haybee Multimedia where he also
                serves as the Chief Executive Officer.
              </div>{" "}
              <div className="aboutsection4para text-start pt-3">
                He has worked with several personalities and notable parastatals
                around the world including the Federal University of Agriculture
                Nigeria, CryptoMaster Class Lagos, Adex Daily Kitchen Dublin,
                among many others.
              </div>{" "}
              <div className="aboutsection4para text-start pt-3">
                He is also the lead coach and instructor at Haybee Multimedia
                Academy where he has raised several personalities who are now
                leading experts in their respective fields. His vision is to use
                the media to help brands to scale up their businesses and
                revenues
              </div>{" "}
            </div>
            <div>
              <img src={wewadimg6} />
              <div className="aboutsection2heading pt-3">
                Mr. Adekunle Abraham
              </div>
              <div className="aboutsection2subheading pt-2">Facebook Admin</div>
            </div>
          </div>
        </div> */}
      </section>
      <section className="homesection6 p-4 p-md-5">
        <div className="homesection3heading">What People Say About Us</div>
        <div className="homesection3subheading">
          Hear from those who have benefited from our programs
        </div>
        <div className="homesection6grid pt-3">
          <ScrollAnimation
            className=""
            animateIn="bounceInUp"
            animationOut="fadeOut"
          >
            <div
              className="testimonialgridbox p-3"
              style={{
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="d-flex">
                <div className="pe-2">
                  <img src={testimonialimg1} alt="" />
                </div>
                <div className="pt-3 ">
                  <div className="testimonialname">Chidinma Okeke</div>
                  <div className="testimoniallocation pt-1">
                    Lagos, Nigeria.
                  </div>
                </div>
              </div>
              <div className="testimonialpara pt-2">
                WEWAD has been a beacon of hope for me. As a single mother, the
                vocational training I received has enabled me to start my own
                small business. Now, I can provide for my family and secure a
                brighter future for my children. Thank you, WEWAD!
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            className=""
            animateIn="bounceInUp"
            animationOut="fadeOut"
          >
            <div
              className="testimonialgridbox p-3"
              style={{
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="d-flex">
                <div className="pe-2">
                  <img src={testimonialimg2} alt="" />
                </div>
                <div className="pt-3 ">
                  <div className="testimonialname">Tunde Adebayo</div>
                  <div className="testimoniallocation pt-1">
                    Ibadan, Nigeria.
                  </div>
                </div>
              </div>
              <div className="testimonialpara pt-2">
                Living with a disability was challenging, but WEWAD's support
                has made a tremendous difference. The mentorship and resources
                provided have empowered me to live independently and pursue my
                career goals. I am forever grateful.
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            className=""
            animateIn="bounceInUp"
            animationOut="fadeOut"
          >
            <div
              className="testimonialgridbox p-3"
              style={{
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="d-flex">
                <div className="pe-2">
                  <img src={testimonialimg3} alt="" />
                </div>
                <div className="pt-3 ">
                  <div className="testimonialname">Ngozi Okoro</div>
                  <div className="testimoniallocation pt-1">
                    Rivers, Nigeria.
                  </div>
                </div>
              </div>
              <div className="testimonialpara pt-2">
                Thanks to WEWAD, I have found a community that supports and
                uplifts me. The programs have not only provided me with
                practical skills but also boosted my confidence. I am now
                running a successful catering business and giving back to my
                community.
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            className=""
            animateIn="bounceInUp"
            animationOut="fadeOut"
          >
            <div
              className="testimonialgridbox p-3"
              style={{
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="d-flex">
                <div className="pe-2">
                  <img src={testimonialimg4} alt="" />
                </div>
                <div className="pt-3 ">
                  <div className="testimonialname">Emeka Nwosu</div>
                  <div className="testimoniallocation pt-1">
                    Enugu, Nigeria.
                  </div>
                </div>
              </div>
              <div className="testimonialpara pt-2">
                WEWAD's empowerment programs have transformed my life. I have
                learned new skills and met wonderful people who inspire me
                daily. With WEWAD's help, I have started a tailoring business,
                and I am proud of what I have achieved.!
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            className=""
            animateIn="bounceInUp"
            animationOut="fadeOut"
          >
            <div
              className="testimonialgridbox p-3"
              style={{
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="d-flex">
                <div className="pe-2">
                  <img src={testimonialimg5} alt="" />
                </div>
                <div className="pt-3 ">
                  <div className="testimonialname">Kemi Adesina</div>
                  <div className="testimoniallocation pt-1">
                    Port Harcourt, Nigeria.
                  </div>
                </div>
              </div>
              <div className="testimonialpara pt-2">
                The inclusive community at WEWAD has been a source of strength
                for me. The resources and guidance I received have helped me
                build a successful career in technology. WEWAD truly changes
                lives, and I am a testament to that.
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            className=""
            animateIn="bounceInUp"
            animationOut="fadeOut"
          >
            <div
              className="testimonialgridbox p-3"
              style={{
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="d-flex">
                <div className="pe-2">
                  <img src={testimonialimg6} alt="" />
                </div>
                <div className="pt-3 ">
                  <div className="testimonialname">Femi Adeoye</div>
                  <div className="testimoniallocation pt-1">
                    Benin City, Nigeria.
                  </div>
                </div>
              </div>
              <div className="testimonialpara pt-2">
                WEWAD's initiatives are life-changing. The vocational training I
                received has opened up new opportunities for me, and I am now
                working in a field I love. WEWAD’s commitment to empowerment is
                evident in everything they do.
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            className=""
            animateIn="bounceInUp"
            animationOut="fadeOut"
          >
            <div
              className="testimonialgridbox p-3"
              style={{
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="d-flex">
                <div className="pe-2">
                  <img src={testimonialimg7} alt="" />
                </div>
                <div className="pt-3 ">
                  <div className="testimonialname">Aisha Bello</div>
                  <div className="testimoniallocation pt-1">
                    Abuja, Nigeria.
                  </div>
                </div>
              </div>
              <div className="testimonialpara pt-2">
                Joining WEWAD was one of the best decisions I’ve ever made. The
                community is incredibly supportive, and the workshops have given
                me valuable skills and confidence. I now feel equipped to face
                any challenge that comes my way.
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            className=""
            animateIn="bounceInUp"
            animationOut="fadeOut"
          >
            <div
              className="testimonialgridbox p-3"
              style={{
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="d-flex">
                <div className="pe-2">
                  <img src={testimonialimg8} alt="" />
                </div>
                <div className="pt-3 ">
                  <div className="testimonialname">Yemi Oladipo</div>
                  <div className="testimoniallocation pt-1">Jos, Nigeria.</div>
                </div>
              </div>
              <div className="testimonialpara pt-2">
                The mentorship and support from WEWAD have been invaluable. I
                have gained skills that have made me more competitive in the job
                market. WEWAD’s focus on inclusivity and empowerment is making a
                real difference in people’s lives.
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            className=""
            animateIn="bounceInUp"
            animationOut="fadeOut"
          >
            <div
              className="testimonialgridbox p-3"
              style={{
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="d-flex">
                <div className="pe-2">
                  <img src={testimonialimg9} alt="" />
                </div>
                <div className="pt-3 ">
                  <div className="testimonialname">Funke Afolayan</div>
                  <div className="testimoniallocation pt-1">
                    Ilorin, Nigeria.
                  </div>
                </div>
              </div>
              <div className="testimonialpara pt-2">
                WEWAD has given me hope and a sense of purpose. The workshops
                and training sessions have equipped me with the tools I need to
                succeed. I am now confidently pursuing my passion for fashion
                design, and I owe it all to WEWAD.
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </section>
    </div>
  );
}
export default About;
