import React from "react";
import "animate.css";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css"; // Import Animate.css styles
import { Link } from "react-router-dom";
import testimonialimg1 from "../images/testimonialimg1.png";
import testimonialimg2 from "../images/testimonialimg2.png";
import testimonialimg3 from "../images/testimonialimg3.png";
import testimonialimg4 from "../images/testimonialimg4.png";
import testimonialimg5 from "../images/testimonialimg5.png";
import testimonialimg6 from "../images/testimonialimg6.png";
import testimonialimg7 from "../images/testimonialimg7.png";
import testimonialimg8 from "../images/testimonialimg8.png";
import testimonialimg9 from "../images/testimonialimg9.png";
import testimonialbackgroundimg from "../images/testimonialbackgroundimg.jfif";

function Contact() {
  return (
    <div className="p-md-5 p-3">
      <section className="homesection6 p-md-5">
        <div className="homesection3heading pt-5">Get in Touch</div>
        <div className="homesection3subheading py-md-4 pb-5 pb-md-3">
          Whether you have questions, need support, or want to learn more about
          WEWAD, we’re here to help. Reach out to us using the contact details
          below, or fill out the contact form, and we’ll get back to you as soon
          as possible.
        </div>
        <div className="contactgrid ">
          <div className="contactsubgrid">
            <div className="contactsubgridheading">Contact Details</div>
            <div className="homesection3grid pt-md-4 pt-1">
              <div className="homesection3grid1">
                <div className="pb-md-3 pb-1">
                  <div className="homesection2subgridgroup">
                    <div className="homesection2subgridheading contactsubgridheading pb-2">
                      Phone
                    </div>
                    <div className="homesection2subgridsubheading">
                      <a className="tellink" href="tel:+19295879945">
                        +1 (929) 587-9945
                      </a>
                    </div>
                    <div className="pt-3">
                      <div className="homesection2subgridsubheading">
                        <a className="tellink" href="tel:+2347071037325">
                          +234 707 103 7325
                        </a>
                      </div>
                    </div>
                    <div className="pt-3">
                      <div className="homesection2subgridsubheading">
                        <a className="tellink" href="tel:+2348055474918">
                        +234 805 547 4918
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pb-md-3 pb-1">
                  <div className="homesection2subgridgroup">
                    <div className="homesection2subgridheading contactsubgridheading pb-2">
                      Email Us
                    </div>
                    <div className="homesection2subgridsubheading">
                      <Link
                        to={`mailto:contact@wewad.org?subject=Interested%20In%20Donations&body=Hello!%20I'm%20interested%20in...`}
                        className="footersection2link"
                      >
                        Contact@wewad.org
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="pb-md-3 pb-1">
                  <div className="homesection2subgridgroup">
                    <div className="homesection2subgridheading contactsubgridheading pb-2">
                      Address​{" "}
                    </div>
                    <div className="homesection2subgridsubheading">
                      8530 Eagle point Blvd, suite 100, Lake Elmo, MN, United
                      States, Minnesota
                    </div>
                    <div className="pt-4">
                      <div className="homesection2subgridsubheading ">
                        54, Makoko Road, Opp Olaiya Mosque, Makoko, Yaba, Lagos
                        State Nigeria.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="contacttext p-3">Follow Us on Social Media</div>
            <div className="contactsocialmediaflex ps-3">
              <div className="contacticons d-flex ">
                <div>
                  <a
                    href="https://web.facebook.com/WomenEmpoweringWomenAndDisabled"
                    class="fa-brands fa-facebook contacticon"
                  ></a>{" "}
                </div>
                {/* <div>
              <a
                href=""
                class="fa-brands fa-twitter contacticon"
              ></a>{" "}
            </div> */}
                <div>
                  <a
                    href="https://www.youtube.com/@WEWADChannel"
                    class="fa-brands fa-youtube contacticon"
                  ></a>{" "}
                </div>
              </div>
            </div>
          </div>

          <div className="pt-4 pt-lg-1 slideRight">
            <form className="contactform">
              <div className="formgroup">
                <div className="formgroupsection pb-3 pb-md-1">
                  <label for="fname">First Name</label>
                  <input
                    type="text"
                    id="fname"
                    name="firstname"
                    placeholder="Enter First Name"
                    className="mobileinput"
                  />
                </div>
                <div className="formgroupsection ">
                  <label for="lname">Last Name</label>
                  <input
                    type="text"
                    id="lname"
                    name="lastname"
                    placeholder="Enter Last Name"
                    className="mobileinput"
                  />
                </div>
              </div>

              <div className="formgroupsection">
                <label for="email">Email</label>
                <input
                  type="email"
                  id="emaill"
                  name="email"
                  placeholder="Enter Your Email "
                  className="maxwidth"
                />
              </div>
              <div className="formgroupsection pt-3">
                <label for="email">Phone</label>
                <input
                  type="phone"
                  id="phone"
                  name="phone"
                  placeholder="Enter Your Phone Number "
                  className="maxwidth"
                />
              </div>
              <div className="formgroupsection pt-3">
                <label for="message">Message</label>
                <textarea
                  className="messageform"
                  id="message"
                  name="message"
                  placeholder="Enter your Message here..."
                ></textarea>
              </div>
              <div className="contactformbtn pt-3">
                <button className="joinusbtn ">
                  <span className="pe-2">Send Message</span>{" "}
                </button>
              </div>
            </form>
          </div>
        </div>
        <section className="homesection6 p-4 p-md-5">
          <div className="homesection3heading">What People Say About Us</div>
          <div className="homesection3subheading">
            Hear from those who have benefited from our programs
          </div>
          <div className="homesection6grid pt-3">
            <ScrollAnimation
              className=""
              animateIn="bounceInUp"
              animationOut="fadeOut"
            >
              <div
                className="testimonialgridbox p-3"
                style={{
                  backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="d-flex">
                  <div className="pe-2">
                    <img src={testimonialimg1} alt="" />
                  </div>
                  <div className="pt-3 ">
                    <div className="testimonialname">Chidinma Okeke</div>
                    <div className="testimoniallocation pt-1">
                      Lagos, Nigeria.
                    </div>
                  </div>
                </div>
                <div className="testimonialpara pt-2">
                  WEWAD has been a beacon of hope for me. As a single mother,
                  the vocational training I received has enabled me to start my
                  own small business. Now, I can provide for my family and
                  secure a brighter future for my children. Thank you, WEWAD!
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              className=""
              animateIn="bounceInUp"
              animationOut="fadeOut"
            >
              <div
                className="testimonialgridbox p-3"
                style={{
                  backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="d-flex">
                  <div className="pe-2">
                    <img src={testimonialimg2} alt="" />
                  </div>
                  <div className="pt-3 ">
                    <div className="testimonialname">Tunde Adebayo</div>
                    <div className="testimoniallocation pt-1">
                      Ibadan, Nigeria.
                    </div>
                  </div>
                </div>
                <div className="testimonialpara pt-2">
                  Living with a disability was challenging, but WEWAD's support
                  has made a tremendous difference. The mentorship and resources
                  provided have empowered me to live independently and pursue my
                  career goals. I am forever grateful.
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              className=""
              animateIn="bounceInUp"
              animationOut="fadeOut"
            >
              <div
                className="testimonialgridbox p-3"
                style={{
                  backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="d-flex">
                  <div className="pe-2">
                    <img src={testimonialimg3} alt="" />
                  </div>
                  <div className="pt-3 ">
                    <div className="testimonialname">Ngozi Okoro</div>
                    <div className="testimoniallocation pt-1">
                      Rivers, Nigeria.
                    </div>
                  </div>
                </div>
                <div className="testimonialpara pt-2">
                  Thanks to WEWAD, I have found a community that supports and
                  uplifts me. The programs have not only provided me with
                  practical skills but also boosted my confidence. I am now
                  running a successful catering business and giving back to my
                  community.
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              className=""
              animateIn="bounceInUp"
              animationOut="fadeOut"
            >
              <div
                className="testimonialgridbox p-3"
                style={{
                  backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="d-flex">
                  <div className="pe-2">
                    <img src={testimonialimg4} alt="" />
                  </div>
                  <div className="pt-3 ">
                    <div className="testimonialname">Emeka Nwosu</div>
                    <div className="testimoniallocation pt-1">
                      Enugu, Nigeria.
                    </div>
                  </div>
                </div>
                <div className="testimonialpara pt-2">
                  WEWAD's empowerment programs have transformed my life. I have
                  learned new skills and met wonderful people who inspire me
                  daily. With WEWAD's help, I have started a tailoring business,
                  and I am proud of what I have achieved.!
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              className=""
              animateIn="bounceInUp"
              animationOut="fadeOut"
            >
              <div
                className="testimonialgridbox p-3"
                style={{
                  backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="d-flex">
                  <div className="pe-2">
                    <img src={testimonialimg5} alt="" />
                  </div>
                  <div className="pt-3 ">
                    <div className="testimonialname">Kemi Adesina</div>
                    <div className="testimoniallocation pt-1">
                      Port Harcourt, Nigeria.
                    </div>
                  </div>
                </div>
                <div className="testimonialpara pt-2">
                  The inclusive community at WEWAD has been a source of strength
                  for me. The resources and guidance I received have helped me
                  build a successful career in technology. WEWAD truly changes
                  lives, and I am a testament to that.
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              className=""
              animateIn="bounceInUp"
              animationOut="fadeOut"
            >
              <div
                className="testimonialgridbox p-3"
                style={{
                  backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="d-flex">
                  <div className="pe-2">
                    <img src={testimonialimg6} alt="" />
                  </div>
                  <div className="pt-3 ">
                    <div className="testimonialname">Femi Adeoye</div>
                    <div className="testimoniallocation pt-1">
                      Benin City, Nigeria.
                    </div>
                  </div>
                </div>
                <div className="testimonialpara pt-2">
                  WEWAD's initiatives are life-changing. The vocational training
                  I received has opened up new opportunities for me, and I am
                  now working in a field I love. WEWAD’s commitment to
                  empowerment is evident in everything they do.
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              className=""
              animateIn="bounceInUp"
              animationOut="fadeOut"
            >
              <div
                className="testimonialgridbox p-3"
                style={{
                  backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="d-flex">
                  <div className="pe-2">
                    <img src={testimonialimg7} alt="" />
                  </div>
                  <div className="pt-3 ">
                    <div className="testimonialname">Aisha Bello</div>
                    <div className="testimoniallocation pt-1">
                      Abuja, Nigeria.
                    </div>
                  </div>
                </div>
                <div className="testimonialpara pt-2">
                  Joining WEWAD was one of the best decisions I’ve ever made.
                  The community is incredibly supportive, and the workshops have
                  given me valuable skills and confidence. I now feel equipped
                  to face any challenge that comes my way.
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              className=""
              animateIn="bounceInUp"
              animationOut="fadeOut"
            >
              <div
                className="testimonialgridbox p-3"
                style={{
                  backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="d-flex">
                  <div className="pe-2">
                    <img src={testimonialimg8} alt="" />
                  </div>
                  <div className="pt-3 ">
                    <div className="testimonialname">Yemi Oladipo</div>
                    <div className="testimoniallocation pt-1">
                      Jos, Nigeria.
                    </div>
                  </div>
                </div>
                <div className="testimonialpara pt-2">
                  The mentorship and support from WEWAD have been invaluable. I
                  have gained skills that have made me more competitive in the
                  job market. WEWAD’s focus on inclusivity and empowerment is
                  making a real difference in people’s lives.
                </div>
              </div>
            </ScrollAnimation>
            <ScrollAnimation
              className=""
              animateIn="bounceInUp"
              animationOut="fadeOut"
            >
              <div
                className="testimonialgridbox p-3"
                style={{
                  backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="d-flex">
                  <div className="pe-2">
                    <img src={testimonialimg9} alt="" />
                  </div>
                  <div className="pt-3 ">
                    <div className="testimonialname">Funke Afolayan</div>
                    <div className="testimoniallocation pt-1">
                      Ilorin, Nigeria.
                    </div>
                  </div>
                </div>
                <div className="testimonialpara pt-2">
                  WEWAD has given me hope and a sense of purpose. The workshops
                  and training sessions have equipped me with the tools I need
                  to succeed. I am now confidently pursuing my passion for
                  fashion design, and I owe it all to WEWAD.
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </section>
      </section>
    </div>
  );
}
export default Contact;
