import React from "react";
import "animate.css";
// import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"; // Import Animate.css styles
// import { Link } from "react-router-dom";
import homesection4img1 from "../images/homesection4img1.jfif";

import testimonialimg1 from "../images/testimonialimg1.png";
import testimonialimg2 from "../images/testimonialimg2.png";
import testimonialimg3 from "../images/testimonialimg3.png";
import testimonialimg4 from "../images/testimonialimg4.png";
import testimonialimg5 from "../images/testimonialimg5.png";
import testimonialimg6 from "../images/testimonialimg6.png";
import testimonialimg7 from "../images/testimonialimg7.png";
import testimonialimg8 from "../images/testimonialimg8.png";
import testimonialimg9 from "../images/testimonialimg9.png";
import testimonialbackgroundimg from "../images/testimonialbackgroundimg.jfif";
import blogimg5 from "../images/blogimg5.png";


function BlogPage5() {
  return (
    <div className="p-md-5 p-4">
      <div className="wh">
        <section className="aboutsection1 py-md-5 pt-5 pb-1">
          <div className="homesection3heading pb-2">
            Supporting People Around You: <br />Why It Matters and How to Do It{" "}
          </div>
          <div className="py-3 ">
            <img src={blogimg5} alt="" className="text-start mobilewidth" />
          </div>
          <div className="blogpagepara py-2">
            Supporting the people around you isn’t just a kind gesture; it’s a
            fundamental part of building strong, resilient communities. Whether
            it’s your friends, family, colleagues, or neighbors, offering
            support can make a significant difference in someone’s life. In this
            blog post, we’ll explore why supporting others is important and
            provide practical tips on how you can be there for the people in
            your life.
          </div>
          <div className="blogpagesubheading pt-4 pb-2">
            Why Supporting People Around You Matters
          </div>
          <div className="blogpagesubheadingsmall pt-2">
            1. Strengthening Relationships
          </div>
          <div className="blogpagepara pt-2">
            Supporting others fosters deeper connections and strengthens
            relationships. When you show empathy and understanding, you build
            trust and mutual respect, creating a supportive environment where
            everyone feels valued and cared for.
          </div>
          <div className="blogpagesubheadingsmall pt-3">
            2. Promoting Mental Well-being
          </div>
          <div className="blogpagepara pt-2">
            Life can be challenging, and everyone faces their own set of
            struggles. Offering support can provide comfort and reassurance,
            helping to reduce feelings of isolation and loneliness. By being
            there for someone, you can play a vital role in promoting their
            mental well-being.
          </div>
          <div className="blogpagesubheadingsmall pt-3">
            3. Creating a Positive Community
          </div>
          <div className="blogpagepara pt-2">
            Supportive communities are happier, healthier, and more harmonious.
            By actively supporting the people around you, you contribute to
            creating a positive community where people feel empowered to help
            one another and work together towards common goals.
          </div>
          <div className="blogpagesubheading pt-4 pb-2">
            Practical Tips for Supporting Others
          </div>
          <div className="blogpagesubheadingsmall pt-3">1. Listen Actively</div>
          <div className="blogpagepara pt-2">
            One of the most powerful ways to support someone is to listen
            actively. Give them your full attention, be empathetic, and avoid
            interrupting or offering unsolicited advice. Sometimes, all someone
            needs is a listening ear.
          </div>
          <div className="blogpagesubheadingsmall pt-3">
            2. Offer Emotional Support
          </div>
          <div className="blogpagepara pt-2">
            Expressing empathy and compassion can go a long way in providing
            emotional support. Let the person know that you care about their
            well-being and are there to support them through both good times and
            bad.
          </div>
          <div className="blogpagesubheadingsmall pt-3">
            3. Provide Practical Help
          </div>
          <div className="blogpagepara pt-2">
            Whether it’s helping with chores, running errands, or offering
            professional advice, providing practical assistance can ease
            someone’s burden and show that you’re willing to go the extra mile
            to help them out.
          </div>
          <div className="blogpagesubheadingsmall pt-3">
            4. Be Respectful and Non-Judgmental
          </div>
          <div className="blogpagepara pt-2">
            Everyone has their own unique experiences and perspectives. Be
            respectful of others’ feelings and avoid passing judgment. Accept
            people for who they are and offer support without imposing your
            beliefs or values on them.
          </div>
          <div className="blogpagesubheadingsmall pt-3">5. Stay Connected</div>
          <div className="blogpagepara pt-2">
            Maintaining regular contact with the people in your life shows that
            you value their presence. Make an effort to check in on them
            regularly, whether it’s through a phone call, text message, or a
            face-to-face catch-up.
          </div>
          <div className="blogpagesubheading pt-4 pb-2">Conclusion</div>
          <div className="blogpagepara pt-2">
            Supporting the people around you is a meaningful way to contribute
            to a happier, healthier, and more compassionate world. By fostering
            strong relationships, promoting mental well-being, and creating a
            positive community, you can make a lasting impact on the lives of
            those around you. Remember, even small acts of kindness can have a
            ripple effect, inspiring others to pay it forward and create a chain
            of support that benefits everyone. So, let’s make an effort to
            support each other and build stronger, more resilient communities
            together!
          </div>{" "}
        </section>
      </div>
      <section className="homesection6 p-md-5">
        <div className="homesection3heading">What People Say About Us</div>
        <div className="homesection3subheading">
          Hear from those who have benefited from our programs
        </div>
        <div className="homesection6grid pt-3">
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg1} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Chidinma Okeke</div>
                <div className="testimoniallocation pt-1">Lagos, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD has been a beacon of hope for me. As a single mother, the
              vocational training I received has enabled me to start my own
              small business. Now, I can provide for my family and secure a
              brighter future for my children. Thank you, WEWAD!
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg2} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Tunde Adebayo</div>
                <div className="testimoniallocation pt-1">Ibadan, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              Living with a disability was challenging, but WEWAD's support has
              made a tremendous difference. The mentorship and resources
              provided have empowered me to live independently and pursue my
              career goals. I am forever grateful.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg3} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Ngozi Okoro</div>
                <div className="testimoniallocation pt-1">Rivers, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              Thanks to WEWAD, I have found a community that supports and
              uplifts me. The programs have not only provided me with practical
              skills but also boosted my confidence. I am now running a
              successful catering business and giving back to my community.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg4} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Emeka Nwosu</div>
                <div className="testimoniallocation pt-1">Enugu, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD's empowerment programs have transformed my life. I have
              learned new skills and met wonderful people who inspire me daily.
              With WEWAD's help, I have started a tailoring business, and I am
              proud of what I have achieved.!
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg5} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Kemi Adesina</div>
                <div className="testimoniallocation pt-1">
                  Port Harcourt, Nigeria.
                </div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              The inclusive community at WEWAD has been a source of strength for
              me. The resources and guidance I received have helped me build a
              successful career in technology. WEWAD truly changes lives, and I
              am a testament to that.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg6} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Femi Adeoye</div>
                <div className="testimoniallocation pt-1">
                  Benin City, Nigeria.
                </div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD's initiatives are life-changing. The vocational training I
              received has opened up new opportunities for me, and I am now
              working in a field I love. WEWAD’s commitment to empowerment is
              evident in everything they do.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg7} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Aisha Bello</div>
                <div className="testimoniallocation pt-1">Abuja, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              Joining WEWAD was one of the best decisions I’ve ever made. The
              community is incredibly supportive, and the workshops have given
              me valuable skills and confidence. I now feel equipped to face any
              challenge that comes my way.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg8} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Yemi Oladipo</div>
                <div className="testimoniallocation pt-1">Jos, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              The mentorship and support from WEWAD have been invaluable. I have
              gained skills that have made me more competitive in the job
              market. WEWAD’s focus on inclusivity and empowerment is making a
              real difference in people’s lives.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg9} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Funke Afolayan</div>
                <div className="testimoniallocation pt-1">Ilorin, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD has given me hope and a sense of purpose. The workshops and
              training sessions have equipped me with the tools I need to
              succeed. I am now confidently pursuing my passion for fashion
              design, and I owe it all to WEWAD.
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default BlogPage5;
