import React from "react";
import "animate.css";
// import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"; // Import Animate.css styles
// import { Link } from "react-router-dom";
import testimonialimg1 from "../images/testimonialimg1.png";
import testimonialimg2 from "../images/testimonialimg2.png";
import testimonialimg3 from "../images/testimonialimg3.png";
import testimonialimg4 from "../images/testimonialimg4.png";
import testimonialimg5 from "../images/testimonialimg5.png";
import testimonialimg6 from "../images/testimonialimg6.png";
import testimonialimg7 from "../images/testimonialimg7.png";
import testimonialimg8 from "../images/testimonialimg8.png";
import testimonialimg9 from "../images/testimonialimg9.png";
import testimonialbackgroundimg from "../images/testimonialbackgroundimg.jfif";
import photo1 from "../images/photo1.png";
import photo2 from "../images/photo2.png";
import photo3 from "../images/photo3.png";
import photo5 from "../images/photo5.png";
import photo6 from "../images/photo6.png";
import photo7 from "../images/photo7.jpg";
import photo8 from "../images/photo8.jpg";
import photo9 from "../images/photo9.jpg";
import photo10 from "../images/photo10.jpg";
import photo11 from "../images/photo11.jpg";
import photo12 from "../images/photo12.jpg";
import photo13 from "../images/photo13.jpg";
import photo14 from "../images/photo14.jpg";
import photo15 from "../images/photo15.jpg";
import photo16 from "../images/photo16.jpg";
import photo17 from "../images/photo17.jpg";
import photo18 from "../images/photo18.jpg";
import photo19 from "../images/photo19.jpg";
import photo20 from "../images/photo20.jpg";
import photo21 from "../images/photo21.jpg";
import photo22 from "../images/photo22.jpg";
import photo23 from "../images/photo23.jpg";
import photo24 from "../images/photo24.jpg";
import photo25 from "../images/photo25.jpg";
import photo26 from "../images/photo26.jpg";
import photo27 from "../images/photo27.jpg";

import vocphoto1 from "../images/vocphoto1.png";
import vocphoto2 from "../images/vocphoto2.png";
import vocphoto3 from "../images/vocphoto3.png";
import vocphoto4 from "../images/vocphoto4.png";
import vocphoto5 from "../images/vocphoto5.png";
import vocphoto6 from "../images/vocphoto6.png";
import vocphoto7 from "../images/vocphoto7.png";
import vocphoto8 from "../images/vocphoto8.png";
import vocphoto9 from "../images/vocphoto9.png";
import vocphoto10 from "../images/vocphoto10.png";

function PhotoGallery() {
  return (
    <div className="p-md-5 p-2">
      <section className="gallerysection1 pb-2 ">
        <div className="homesection3heading pb-2">Photo Gallery</div>
        <div className="homesection3subheading px-4 pb-4">
          Explore photos from our key events and initiatives where we come
          together to empower and support women and individuals with
          disabilities. These moments capture the spirit of community, learning,
          and growth that define WEWAD.
        </div>
        <div className="gallerygroup">
          <div className="twocolumngrid">
            <div>
              <img src={photo1} className="photo" alt="" />
            </div>
            <div>
              <img src={photo2} className="photo" alt="" />
            </div>
          </div>
          <div>
            <img src={photo3} className="photo py-2" alt="" />
          </div>
          <div className="twocolumngrid">
            <div>
              <img src={photo5} className="photo" alt="" />
            </div>
            <div>
              <img src={photo6} className="photo" alt="" />
            </div>
            </div>
          <div className="">
            <div >
              {" "}
              <img src={photo7} alt=""className="newphoto" />
            </div>
            <div  >
              {" "}
              <img src={photo8} alt="" className="newphoto"/>
            </div>
            <div  >
              {" "}
              <img src={photo9} alt="" className="newphoto"/>
            </div>
            <div  >
              {" "}
              <img src={photo10} alt=""  className="newphoto"/>
            </div>
            <div  >
              {" "}
              <img src={photo11} alt="" className="newphoto"/>
            </div>
            <div  >
              {" "}
              <img src={photo12} alt="" className="newphoto" />
            </div>
            <div  >
              {" "}
              <img src={photo13} alt="" className="newphoto" />
            </div>
            <div  >
              {" "}
              <img src={photo14} alt=""  className="newphoto"/>
            </div>
            <div  >
              {" "}
              <img src={photo15} alt="" className="newphoto"/>
            </div>
           
            <div  >
              {" "}
              <img src={photo17} alt="" className="newphoto"/>
            </div>
            <div  >
              {" "}
              <img src={photo18} alt="" className="newphoto"/>
            </div>
            <div  >
              {" "}
              <img src={photo19} alt="" className="newphoto"/>
            </div>
            <div  >
              {" "}
              <img src={photo20} alt="" className="newphoto"/>
            </div>
            <div  >
              {" "}
              <img src={photo21} alt="" className="newphoto"/>
            </div>
            <div  >
              {" "}
              <img src={photo22} alt="" className="newphoto"/>
            </div>
            <div  >
              {" "}
              <img src={photo23} alt="" className="newphoto"/>
            </div>
            <div  >
              {" "}
              <img src={photo24} alt="" className="newphoto"/>
            </div>
            <div  >
              {" "}
              <img src={photo25} alt="" className="newphoto"/>
            </div>
            <div >
              {" "}
              <img src={photo26} alt="" className="newphoto"/>
            </div>
            <div>
              {" "}
              <img src={photo27} alt="" className="newphoto"/>
            </div>
          </div>
        </div>
        <div className="gallerysubgroup pt-5">
          <div>
            <div className="homesection3heading pb-2 text-start pt-5">
              Vocational Training Sessions
            </div>
            <div className="homesection3subheading text-start pb-4">
              Witness the transformation as participants gain new skills and
              knowledge through our vocational training programs. <br /> These
              photos highlight the hands-on learning and the dedication of both
              trainers and trainees.
            </div>
          </div>
          <div className="gallerysubgroupbtn">
            {" "}
            <button className="donatebtn">
              {" "}
              <a href="https://docs.google.com/forms/d/1iNM6CzONKNap5DlIKIT9XyqsSKJt-ewTI4hmiU5m_kE/viewform?edit_requested=true">
                Join Us <i class="ps-2 fa-solid fa-arrow-right-long"></i>
              </a>
            </button>{" "}
          </div>
        </div>
        <div className="gallerygroup">
          <div className="threecolumngrid">
            <div className="photo">
              {" "}
              <img src={vocphoto1} alt="" />
            </div>
            <div className="photo">
              {" "}
              <img src={vocphoto2} alt="" />
            </div>
            <div className="photo">
              {" "}
              <img src={vocphoto3} alt="" />
            </div>
            <div className="photo">
              {" "}
              <img src={vocphoto4} alt="" />
            </div>
            <div className="photo">
              {" "}
              <img src={vocphoto5} alt="" />
            </div>
            <div className="photo">
              {" "}
              <img src={vocphoto6} alt="" />
            </div>
           
          </div>
          <div className="fourcolumngrid pt-4">
            <div className="photo">
              {" "}
              <img src={vocphoto7} alt="" />
            </div>
            <div className="photo">
              {" "}
              <img src={vocphoto8} alt="" />
            </div>
            <div className="photo">
              {" "}
              <img src={vocphoto9} alt="" />
            </div>
            <div className="photo">
              {" "}
              <img src={vocphoto10} alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="homesection6 p-md-5 p-3">
        <div className="homesection3heading">What People Say About Us</div>
        <div className="homesection3subheading">
          Hear from those who have benefited from our programs
        </div>
        <div className="homesection6grid pt-3">
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg1} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Chidinma Okeke</div>
                <div className="testimoniallocation pt-1">Lagos, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD has been a beacon of hope for me. As a single mother, the
              vocational training I received has enabled me to start my own
              small business. Now, I can provide for my family and secure a
              brighter future for my children. Thank you, WEWAD!
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg2} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Tunde Adebayo</div>
                <div className="testimoniallocation pt-1">Ibadan, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              Living with a disability was challenging, but WEWAD's support has
              made a tremendous difference. The mentorship and resources
              provided have empowered me to live independently and pursue my
              career goals. I am forever grateful.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg3} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Ngozi Okoro</div>
                <div className="testimoniallocation pt-1">Rivers, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              Thanks to WEWAD, I have found a community that supports and
              uplifts me. The programs have not only provided me with practical
              skills but also boosted my confidence. I am now running a
              successful catering business and giving back to my community.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg4} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Emeka Nwosu</div>
                <div className="testimoniallocation pt-1">Enugu, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD's empowerment programs have transformed my life. I have
              learned new skills and met wonderful people who inspire me daily.
              With WEWAD's help, I have started a tailoring business, and I am
              proud of what I have achieved.!
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg5} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Kemi Adesina</div>
                <div className="testimoniallocation pt-1">
                  Port Harcourt, Nigeria.
                </div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              The inclusive community at WEWAD has been a source of strength for
              me. The resources and guidance I received have helped me build a
              successful career in technology. WEWAD truly changes lives, and I
              am a testament to that.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg6} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Femi Adeoye</div>
                <div className="testimoniallocation pt-1">
                  Benin City, Nigeria.
                </div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD's initiatives are life-changing. The vocational training I
              received has opened up new opportunities for me, and I am now
              working in a field I love. WEWAD’s commitment to empowerment is
              evident in everything they do.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg7} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Aisha Bello</div>
                <div className="testimoniallocation pt-1">Abuja, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              Joining WEWAD was one of the best decisions I’ve ever made. The
              community is incredibly supportive, and the workshops have given
              me valuable skills and confidence. I now feel equipped to face any
              challenge that comes my way.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg8} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Yemi Oladipo</div>
                <div className="testimoniallocation pt-1">Jos, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              The mentorship and support from WEWAD have been invaluable. I have
              gained skills that have made me more competitive in the job
              market. WEWAD’s focus on inclusivity and empowerment is making a
              real difference in people’s lives.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg9} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Funke Afolayan</div>
                <div className="testimoniallocation pt-1">Ilorin, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD has given me hope and a sense of purpose. The workshops and
              training sessions have equipped me with the tools I need to
              succeed. I am now confidently pursuing my passion for fashion
              design, and I owe it all to WEWAD.
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default PhotoGallery;
