import React from "react";
import "animate.css";
// import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"; // Import Animate.css styles
// import { Link } from "react-router-dom";
import homesection4img1 from "../images/homesection4img1.jfif";

import testimonialimg1 from "../images/testimonialimg1.png";
import testimonialimg2 from "../images/testimonialimg2.png";
import testimonialimg3 from "../images/testimonialimg3.png";
import testimonialimg4 from "../images/testimonialimg4.png";
import testimonialimg5 from "../images/testimonialimg5.png";
import testimonialimg6 from "../images/testimonialimg6.png";
import testimonialimg7 from "../images/testimonialimg7.png";
import testimonialimg8 from "../images/testimonialimg8.png";
import testimonialimg9 from "../images/testimonialimg9.png";
import testimonialbackgroundimg from "../images/testimonialbackgroundimg.jfif";
import blogpageimg2 from "../images/blogpageimg2.jpg";
import blogpageimg3 from "../images/blogpageimg3.jpg";
import blogpageimg4 from "../images/blogpageimg4.jpg";
import blogpageimg5 from "../images/blogpageimg5.jpg";
import blogpageimg6 from "../images/blogpageimg6.jpg";

function BlogPage1() {
  return (
    <div className="p-md-5 p-3">
        <div className="wh">
      <section className="aboutsection1">
        
        <div className="homesection3heading pb-2 blogpage1heading">
          WEWAD Foundation Empowers a Young Lady with Beautician Accessories
        </div>
        <div className="py-3">
          <img src={blogpageimg2} alt="" className="mobilewidth "/>
        </div>

        <div className="blogpagepara">
          The Women Empowering Women and Disabled (WEWAD) Foundation recently
          empowered Kafayat Ajoke Mohammed with a set of beautician accessories,
          fueling her passion for the beauty industry. Kafayat, brimming with
          gratitude, conveyed her heartfelt appreciation to the foundation for
          this transformative opportunity.
          <br /> <br />
          Renowned for its dedication to fostering inclusivity and empowering
          women and disabled individuals, the WEWAD Foundation once again
          demonstrated its profound impact on lives, exemplified through
          Kafayat’s story. By equipping her with essential tools, the foundation
          has unlocked avenues for her to forge a prosperous career.
          <br />
          <br />
          Kafayat, buoyed by overwhelming joy, voiced her profound gratitude for
          the foundation’s steadfast support, expressing her optimism for
          WEWAD’s continued ascent in their noble pursuit. This empowering
          endeavor not only facilitates financial independence but also nurtures
          confidence and self-esteem in individuals like Kafayat.
          <br /> <br />
          Under the visionary leadership of Funke Phillips, the WEWAD Foundation
          persists in its mission to uplift and empower women and disabled
          individuals. Their relentless efforts in championing inclusivity and
          fostering opportunities for personal and professional advancement
          merit commendation.
        </div>
      </section>
      <section className="blogpage1pictures">
        <div className="twocolumngrid twentypxgap pt-4">
          <div>
            <img className="blogpageimg" src={blogpageimg2} alt="" />
          </div>
          <div>
            <img className="blogpageimg" src={blogpageimg3} alt="" />
          </div>
        </div>
        <div className="threecolumngrid twentypxgap py-3">
          <div>
            <img className="blogpageimg" src={blogpageimg4} alt="" />
          </div>
          <div>
            <img className="blogpageimg" src={blogpageimg5} alt="" />
          </div>
          <div>
            <img className="blogpageimg" src={blogpageimg6} alt="" />
          </div>
        </div>
        <div className="blogpagepara">
          Kafayat Ajoke Mohammed’s journey epitomizes the transformative
          influence of organizations like the WEWAD Foundation. Through their
          unwavering support and empowerment, dreams materialize, and obstacles
          dissipate.
          <br /> <br />
          We extend heartfelt congratulations to Kafayat Ajoke Mohammed on her
          empowerment and express profound gratitude to the WEWAD Foundation for
          their enduring dedication to effecting positive change in the lives of
          women and disabled individuals. Together, let us cultivate a society
          that embraces inclusivity and empowerment.
        </div>
        
      </section>
      </div>
      <section className="homesection6 p-md-5">
        <div className="homesection3heading">What People Say About Us</div>
        <div className="homesection3subheading">
          Hear from those who have benefited from our programs
        </div>
        <div className="homesection6grid pt-3">
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg1} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Chidinma Okeke</div>
                <div className="testimoniallocation pt-1">Lagos, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD has been a beacon of hope for me. As a single mother, the
              vocational training I received has enabled me to start my own
              small business. Now, I can provide for my family and secure a
              brighter future for my children. Thank you, WEWAD!
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg2} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Tunde Adebayo</div>
                <div className="testimoniallocation pt-1">Ibadan, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              Living with a disability was challenging, but WEWAD's support has
              made a tremendous difference. The mentorship and resources
              provided have empowered me to live independently and pursue my
              career goals. I am forever grateful.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg3} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Ngozi Okoro</div>
                <div className="testimoniallocation pt-1">Rivers, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              Thanks to WEWAD, I have found a community that supports and
              uplifts me. The programs have not only provided me with practical
              skills but also boosted my confidence. I am now running a
              successful catering business and giving back to my community.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg4} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Emeka Nwosu</div>
                <div className="testimoniallocation pt-1">Enugu, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD's empowerment programs have transformed my life. I have
              learned new skills and met wonderful people who inspire me daily.
              With WEWAD's help, I have started a tailoring business, and I am
              proud of what I have achieved.!
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg5} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Kemi Adesina</div>
                <div className="testimoniallocation pt-1">
                  Port Harcourt, Nigeria.
                </div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              The inclusive community at WEWAD has been a source of strength for
              me. The resources and guidance I received have helped me build a
              successful career in technology. WEWAD truly changes lives, and I
              am a testament to that.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg6} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Femi Adeoye</div>
                <div className="testimoniallocation pt-1">
                  Benin City, Nigeria.
                </div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD's initiatives are life-changing. The vocational training I
              received has opened up new opportunities for me, and I am now
              working in a field I love. WEWAD’s commitment to empowerment is
              evident in everything they do.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg7} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Aisha Bello</div>
                <div className="testimoniallocation pt-1">Abuja, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              Joining WEWAD was one of the best decisions I’ve ever made. The
              community is incredibly supportive, and the workshops have given
              me valuable skills and confidence. I now feel equipped to face any
              challenge that comes my way.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg8} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Yemi Oladipo</div>
                <div className="testimoniallocation pt-1">Jos, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              The mentorship and support from WEWAD have been invaluable. I have
              gained skills that have made me more competitive in the job
              market. WEWAD’s focus on inclusivity and empowerment is making a
              real difference in people’s lives.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg9} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Funke Afolayan</div>
                <div className="testimoniallocation pt-1">Ilorin, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD has given me hope and a sense of purpose. The workshops and
              training sessions have equipped me with the tools I need to
              succeed. I am now confidently pursuing my passion for fashion
              design, and I owe it all to WEWAD.
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default BlogPage1;
