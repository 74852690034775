import React from "react";
import "animate.css";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css"; // Import Animate.css styles
// import { Link } from "react-router-dom";
import femalememoji from "../images/femalememoji.png";
import leftarrow from "../images/leftarrow.png";
import rightarrow from "../images/rightarrow.png";
import wewadimg1 from "../images/wewadimg1.png";
import homesection3img from "../images/homesection3img.jfif";
import homesection4img1 from "../images/homesection4img1.jfif";
import homesection4img2 from "../images/homesection4img2.jpg";
import homesection4img3 from "../images/homesection4img3.jpg";
import homesection5img1 from "../images/homesection5img1.jfif";
import homesection5img2 from "../images/homesection5img2.jfif";
import homesection5img3 from "../images/newhomesection5img3.png";
import homesection5img4 from "../images/homesection5img4.jfif";
import testimonialimg1 from "../images/testimonialimg1.png";
import testimonialimg2 from "../images/testimonialimg2.png";
import testimonialimg3 from "../images/testimonialimg3.png";
import testimonialimg4 from "../images/testimonialimg4.png";
import testimonialimg5 from "../images/testimonialimg5.png";
import testimonialimg6 from "../images/testimonialimg6.png";
import testimonialimg7 from "../images/testimonialimg7.png";
import testimonialimg8 from "../images/testimonialimg8.png";
import testimonialimg9 from "../images/testimonialimg9.png";
import testimonialbackgroundimg from "../images/testimonialbackgroundimg.jfif";

function Home() {
  return (
    <div>
      <section className="homesection1">
        <div className="memojicontainer">
          <img className="femalememoji" src={femalememoji} alt="femalememoji" />
          <span className="femalememojipara">
            Creating a world where everyone can reach their full potential
          </span>
          <img className="femalememoji" src={femalememoji} alt="femalememoji" />
        </div>
        <div className="homesection1heading py-3 px-2">
          Empowering Women and  Individuals with Disabilities
        </div>
        <div className="homesection1para px-2">
          At WEWAD, we believe in a future where everyone, regardless of gender,
          ability, age,  background, tribe, or religion, is given the
          opportunity to thrive.
        </div>
        <div className="homesectiongroup ">
          <div>
            <img src={leftarrow} alt="leftarrow" className="d-none d-md-block" />
          </div>
          <div className="homesectionsubgroup">
            <button className="joinusbtn">
              <a href="https://docs.google.com/forms/d/1iNM6CzONKNap5DlIKIT9XyqsSKJt-ewTI4hmiU5m_kE/viewform?edit_requested=true">
                {" "}
                Join Us
              </a>
            </button>{" "}
          </div>
          <div className="homesectionsubgroup">
            <a href="/donate">
              <button className="donatebtn"> Donate Now</button>{" "}
            </a>
          </div>
          <div>
            <img src={rightarrow} alt=""  className="d-none d-md-block"/>
          </div>
        </div>
        <div className="pt-4 section1imagewrap">
          <img src={wewadimg1} className="section1image" />
        </div>
      </section>
      <section className="homesection2 p-4 p-md-5">
        <div className="homesection2dotpara ps-md-3 ">
          {" "}
          <span className="thick-dot"></span>
          <span className="ps-2">About WEWAD</span>
        </div>
        <div>
          <div className="homesection2grid2para pb-4">
            {" "}
            <span className="bluehomesection2grid2para">
              WEWAD (Women Empowering Women and Disabled){" "}
            </span>
            is a charity organization committed to creating a world where every
            individual, regardless of gender, ability, age, background, tribe,
            or religion, is empowered to reach their full potential. Our mission
            is to foster a supportive and inclusive community where people,
            especially women and individuals with disabilities, can access
            resources, mentorship, and opportunities for personal and
            professional growth.
          </div>
          <div className=" homesection2subgrid">
          <ScrollAnimation
            className=""
            animateIn="bounceInLeft"
            animationOut="fadeOut"
          >
            <div className="homesection2subgridgroup">
              <div className="homesection2subgridheading pb-2">Our Vision</div>
              <div className="homesection2subgridsubheading">
                To create a world were every individual, regardless of gender,
                ability, age, background, tribe, or religion, is empowered to
                reach their full potential, including support for those affected
                by HIV/AIDS, seeking IVF treatments, managing hepatitis B, and
                fighting poverty, fostering a society built on equality,
                diversity, and inclusivity.
              </div>
            </div>
            </ScrollAnimation>
            <ScrollAnimation
            className=""
            animateIn="bounceInRight"
            animationOut="fadeOut"
          >
            <div className="homesection2subgridgroup">
              <div className="homesection2subgridheading pb-2">Our Mission</div>
              <div className="homesection2subgridsubheading">
                To create a supportive and inclusive community where people
                (most especially women), individuals with disabilities can
                access resources, mentorship, and opportunities for personal and
                professional growth.
              </div>
            </div>
            </ScrollAnimation>
          </div>
          <div className="pt-4">
            {" "}
            <button className="donatebtn">
              {" "}
              <a href="/about">
                Learn More <i class="ps-2 fa-solid fa-arrow-right"></i>
              </a>
            </button>{" "}
          </div>
        </div>
      </section>
      <section className="homesection3 p-3 p-md-5">
        <div className="homesection3heading">Our Impact</div>
        <div className="homesection3subheading">
          WEWAD's initiatives have transformed lives across communities. Our
          impact includes:
        </div>
        
        <div className="homesection3grid pt-4">
        <ScrollAnimation
            className=""
            animateIn="bounceInLeft"
            animationOut="fadeOut"
          >
          <div className="homesection3grid1">
            <div className="pb-3">
              <div className="homesection2subgridgroup">
                <div className="homesection2subgridheading pb-2">
                  Empowered Women
                </div>
                <div className="homesection2subgridsubheading">
                  Over 10,000 women have gained skills, confidence, and
                  opportunities through our programs.
                </div>
              </div>
            </div>

            <div className="pb-3">
              <div className="homesection2subgridgroup">
                <div className="homesection2subgridheading pb-2">
                  Supported Individuals with Disabilities
                </div>
                <div className="homesection2subgridsubheading">
                  More than 5,000 individuals with disabilities have received
                  support, training, and resources to lead independent lives.
                </div>
              </div>
            </div>
            <div className="pb-3">
              <div className="homesection2subgridgroup">
                <div className="homesection2subgridheading pb-2">
                  Transformed Communities
                </div>
                <div className="homesection2subgridsubheading">
                  Our outreach and community development programs have
                  positively impacted over 15,000 families.
                </div>
              </div>
            </div>
            <div className="pt-md-4 pt-2">
              {" "}
              <button className="donatebtn">
                {" "}
                <a href="/blog">
                  See Our Stories <i class="ps-2 fa-solid fa-arrow-right"></i>
                </a>
              </button>{" "}
            </div>
          </div>
          </ScrollAnimation>
          <ScrollAnimation
            className=""
            animateIn="bounceInRight"
            animationOut="fadeOut"
          >
          <div>
            <img src={homesection3img} className="homesection3img" />
          </div>
          </ScrollAnimation>
        </div>
      </section>
      <section className="homesection4 p-3 p-md-5">
        <div className="homesection3heading">Get Involved</div>
        <div className="homesection3subheading">
          Your support can make a real difference. Here are ways you can get
          involved:
        </div>
        <div className="homesection3grid pt-4">
          <div className="pb-3">
          <ScrollAnimation
            className=""
            animateIn="bounceInLeft"
            animationOut="fadeOut"
          >
            <div className="homesection2subgridgroup">
              <div>
                <img
                  className="homesection4img"
                  src={homesection4img1}
                  alt=""
                />
              </div>
              
              <div className="homesection2subgridheading pt-3 pb-2">
                Join Our Creative Volunteers Team
              </div>
              <div className="pt-2 homesection2subgridsubheading homesection4subgridsubheading">
                We are thrilled to announce that WEWAD is seeking talented
                volunteers with expertise in various tech skills, including
                graphic design, cinematography, content writing, and more. If
                you are interested in contributing your skills to WEWAD as a
                volunteer, please visit our website at www.wewad.org and
                complete the application form. Please note, while this is an
                unpaid volunteer position, a small token will be provided to
                cover data expenses.
              </div>
              <div className="pt-4">
                {" "}
                <button className="donatebtn">
                  {" "}
                  <a href="https://docs.google.com/forms/d/1iNM6CzONKNap5DlIKIT9XyqsSKJt-ewTI4hmiU5m_kE/viewform?edit_requested=true">
                    Click to Join <i class="ps-2 fa-solid fa-arrow-right"></i>
                  </a>
                </button>{" "}
              </div>
            </div>
            </ScrollAnimation>
          </div>
          <ScrollAnimation
            className=""
            animateIn="bounceInRight"
            animationOut="fadeOut"
          >
          <div>
            <div className="homesection2subgridgroup">
              <div>
                <img
                  className="homesection4img"
                  src={homesection4img2}
                  alt=""
                />
              </div>
              <div className="homesection2subgridheading pt-3 pb-2">
                Join WEWAD Volunteers
              </div>
              <div className="pt-2 homesection2subgridsubheading homesection4subgridsubheading">
                VOLUNTEERS NEEDED across all States. If you are interested to
                join us{" "}
              </div>
              <div className="pt-4">
                {" "}
                <button className="donatebtn">
                  {" "}
                  <a href="https://docs.google.com/forms/d/1iNM6CzONKNap5DlIKIT9XyqsSKJt-ewTI4hmiU5m_kE/viewform?edit_requested=true">
                    Click to Join <i class="ps-2 fa-solid fa-arrow-right"></i>
                  </a>
                </button>{" "}
              </div>
            </div>
            <div className="pt-3">
              <div className="homesection2subgridgroup">
                <div>
                  <img
                    className="homesection4img"
                    src={homesection4img3}
                    alt=""
                  />
                </div>
                <div className="homesection2subgridheading pt-3 pb-2">
                  Join WEWAD Whatsapp Family Group{" "}
                </div>
                <div className="pt-2 homesection2subgridsubheading homesection4subgridsubheading">
                  Join our Whatsapp Group for special programmes and to keep you
                  upto date about Women Empowering Women And Disabled(WEWAD)
                </div>
                <div className="pt-4">
                  {" "}
                  <button className="donatebtn">
                    {" "}
                    <a href="https://docs.google.com/forms/d/1iNM6CzONKNap5DlIKIT9XyqsSKJt-ewTI4hmiU5m_kE/viewform?edit_requested=true">
                      Click to Join <i class="ps-2 fa-solid fa-arrow-right"></i>
                    </a>{" "}
                  </button>{" "}
                </div>
              </div>
            </div>
          </div>
          </ScrollAnimation>
        </div>
      </section>
      <section className="homesection5 p-4 p-md-5">
        <div className="homesection3heading pb-3 text-start">
          Check Out Our Recent Projects
        </div>
        <div className="homesection3grid">
          <div className="homesection3subheading text-start">
            At the heart of our mission lies a deep commitment to impacting
            lives positively. Regardless of tribe, age, or religion, we strive
            to create meaningful change and uplift communities. Explore some of
            our recent projects that reflect this dedication to fostering unity
            and inclusivity.
          </div>
          <div>
            {" "}
            <button className="donatebtn">
              {" "}
              <a href="https://docs.google.com/forms/d/1iNM6CzONKNap5DlIKIT9XyqsSKJt-ewTI4hmiU5m_kE/viewform?edit_requested=true">
                Click to Join <i class="ps-2 fa-solid fa-arrow-right"></i>
              </a>
            </button>
          </div>
        </div>
        <div className="homesection5grid pt-3">
          <div>
            <img src={homesection5img1} className="homesection3img" />
          </div>
          <div>
            <img src={homesection5img2} className="homesection3img" />
          </div>
          <div>
            <img
              src={homesection5img3}
              className="homesection3img homesection5img3"
            />
          </div>
          <div>
            <img src={homesection5img4} className="homesection3img" />
          </div>
        </div>
      </section>
      <section className="homesection6 p-3 p-md-5">
        <div className="homesection3heading">What People Say About Us</div>
        <div className="homesection3subheading">
          Hear from those who have benefited from our programs
        </div>
        <div className="homesection6grid pt-3">
        <ScrollAnimation className="" animateIn="bounceInUp" animationOut="fadeOut">

          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg1} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Chidinma Okeke</div>
                <div className="testimoniallocation pt-1">Lagos, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD has been a beacon of hope for me. As a single mother, the
              vocational training I received has enabled me to start my own
              small business. Now, I can provide for my family and secure a
              brighter future for my children. Thank you, WEWAD!
            </div>
          </div>
          </ScrollAnimation>
          <ScrollAnimation className="" animateIn="bounceInUp" animationOut="fadeOut">

          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg2} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Tunde Adebayo</div>
                <div className="testimoniallocation pt-1">Ibadan, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              Living with a disability was challenging, but WEWAD's support has
              made a tremendous difference. The mentorship and resources
              provided have empowered me to live independently and pursue my
              career goals. I am forever grateful.
            </div>
          </div>
          </ScrollAnimation>
          <ScrollAnimation className="" animateIn="bounceInUp" animationOut="fadeOut">

          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg3} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Ngozi Okoro</div>
                <div className="testimoniallocation pt-1">Rivers, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              Thanks to WEWAD, I have found a community that supports and
              uplifts me. The programs have not only provided me with practical
              skills but also boosted my confidence. I am now running a
              successful catering business and giving back to my community.
            </div>
          </div>
          </ScrollAnimation>
          <ScrollAnimation className="" animateIn="bounceInUp" animationOut="fadeOut">

          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg4} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Emeka Nwosu</div>
                <div className="testimoniallocation pt-1">Enugu, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD's empowerment programs have transformed my life. I have
              learned new skills and met wonderful people who inspire me daily.
              With WEWAD's help, I have started a tailoring business, and I am
              proud of what I have achieved.!
            </div>
          </div>
          </ScrollAnimation>
          <ScrollAnimation className="" animateIn="bounceInUp" animationOut="fadeOut">

          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg5} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Kemi Adesina</div>
                <div className="testimoniallocation pt-1">
                  Port Harcourt, Nigeria.
                </div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              The inclusive community at WEWAD has been a source of strength for
              me. The resources and guidance I received have helped me build a
              successful career in technology. WEWAD truly changes lives, and I
              am a testament to that.
            </div>
          </div>
          </ScrollAnimation>
          <ScrollAnimation className="" animateIn="bounceInUp" animationOut="fadeOut">

          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg6} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Femi Adeoye</div>
                <div className="testimoniallocation pt-1">
                  Benin City, Nigeria.
                </div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD's initiatives are life-changing. The vocational training I
              received has opened up new opportunities for me, and I am now
              working in a field I love. WEWAD’s commitment to empowerment is
              evident in everything they do.
            </div>
          </div>
          </ScrollAnimation>
             <ScrollAnimation className="" animateIn="bounceInUp" animationOut="fadeOut">

          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg7} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Aisha Bello</div>
                <div className="testimoniallocation pt-1">Abuja, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              Joining WEWAD was one of the best decisions I’ve ever made. The
              community is incredibly supportive, and the workshops have given
              me valuable skills and confidence. I now feel equipped to face any
              challenge that comes my way.
            </div>
          </div>
          </ScrollAnimation>
          <ScrollAnimation className="" animateIn="bounceInUp" animationOut="fadeOut">

          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg8} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Yemi Oladipo</div>
                <div className="testimoniallocation pt-1">Jos, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              The mentorship and support from WEWAD have been invaluable. I have
              gained skills that have made me more competitive in the job
              market. WEWAD’s focus on inclusivity and empowerment is making a
              real difference in people’s lives.
            </div>
          </div>
          </ScrollAnimation>
          <ScrollAnimation className="" animateIn="bounceInUp" animationOut="fadeOut">

          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg9} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Funke Afolayan</div>
                <div className="testimoniallocation pt-1">Ilorin, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD has given me hope and a sense of purpose. The workshops and
              training sessions have equipped me with the tools I need to
              succeed. I am now confidently pursuing my passion for fashion
              design, and I owe it all to WEWAD.
            </div>
          </div>
          </ScrollAnimation>
        </div>
      </section>
      <section className="homesection7 p-3 p-md-5">
        <div className="homesection3heading">Your Questions, Answered</div>
        <div className="homesection3subheading">
          Welcome to the WEWAD FAQ section! Here, we’ve compiled answers to some
          of the most common questions about our organization, programs, and
          ways to get involved.{" "}
        </div>

        <div className="FAQs">
          <div className="py-2 px-md-5 ">
            <div className="FAQ">
              <div
                className="accordion"
                id="faqAccordion"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      <div className="d-md-flex">
                        <div className=" ps-md-4 faqanswertitle pt-3">
                          What is WEWAD?
                        </div>
                      </div>
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body pt-3 pb-4 ">
                      WEWAD (Women Empowering Women and Disabled) is a Nigerian
                      charity organization dedicated to empowering women and
                      individuals with disabilities. We provide resources,
                      mentorship, and opportunities for personal and
                      professional growth to foster a more inclusive and
                      equitable society.
                    </div>
                  </div>
                </div>

                {/* ... other FAQ items with similar structure */}
              </div>
            </div>
          </div>
          <div className="py-2 px-md-5 ">
            <div className="FAQ">
              <div
                className="accordion"
                id="faqAccordion"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <div className="d-md-flex">
                        <div className=" ps-md-4 faqanswertitle pt-3">
                          How can I get involved with WEWAD?{" "}
                        </div>
                      </div>
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body pt-3 pb-4 ">
                      <ol>
                        <li>
                          Volunteer: Contribute your time and skills to support
                          our programs and initiatives.
                        </li>
                        <li>
                          Donate: Your financial contributions help us provide
                          essential services and support to those in need.
                        </li>
                        <li>
                          Become a Member: Join our community and enjoy
                          exclusive benefits while supporting our mission.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>

                {/* ... other FAQ items with similar structure */}
              </div>
            </div>
          </div>
          <div className="py-2 px-md-5 ">
            <div className="FAQ">
              <div
                className="accordion"
                id="faqAccordion"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <div className="d-md-flex">
                        <div className=" ps-md-4 faqanswertitle pt-3">
                          What programs and services does WEWAD offer?{" "}
                        </div>
                      </div>
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body pt-3 pb-4 ">
                      WEWAD offers a variety of programs and services,
                      including:
                      <ul>
                        <li>Vocational training and skills development</li>
                        <li>Mentorship and career guidance</li>
                        <li>Educational resources and workshops</li>
                        <li>Community outreach and support initiatives</li>
                      </ul>
                    </div>
                  </div>
                </div>

                {/* ... other FAQ items with similar structure */}
              </div>
            </div>
          </div>
          <div className="py-2 px-md-5 ">
            <div className="FAQ">
              <div
                className="accordion"
                id="faqAccordion"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      <div className="d-md-flex">
                        <div className=" ps-md-4 faqanswertitle pt-3">
                          Who can benefit from WEWAD's programs?{" "}
                        </div>
                      </div>
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body pt-3 pb-4 ">
                      WEWAD’s programs are designed to support women and
                      individuals with disabilities, regardless of their age,
                      background, tribe, or religion. We aim to empower anyone
                      who seeks personal and professional growth.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-2 px-md-5 ">
            <div className="FAQ">
              <div
                className="accordion"
                id="faqAccordion"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFive">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      <div className="d-md-flex">
                        <div className=" ps-md-4 faqanswertitle pt-3">
                          How are donations used by WEWAD?
                        </div>
                      </div>
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body pt-3 pb-4 ">
                      Donations to WEWAD are used to fund our various programs
                      and initiatives. This includes vocational training,
                      educational resources, community outreach, and support
                      services. We ensure that every contribution directly
                      benefits those in need.{" "}
                    </div>
                  </div>
                </div>

                {/* ... other FAQ items with similar structure */}
              </div>
            </div>
          </div>
          <div className="py-2 px-md-5 ">
            <div className="FAQ">
              <div
                className="accordion"
                id="faqAccordion"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingSix">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      <div className="d-md-flex">
                        <div className=" ps-md-4 faqanswertitle pt-3">
                          Can I volunteer if I am not located in Nigeria?{" "}
                        </div>
                      </div>
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSix"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body pt-3 pb-4 ">
                      Yes, we welcome volunteers from all over the world. There
                      are various ways to contribute remotely, such as offering
                      mentorship, conducting virtual workshops, or supporting
                      our online initiatives.{" "}
                    </div>
                  </div>
                </div>

                {/* ... other FAQ items with similar structure */}
              </div>
            </div>
          </div>
          <div className="py-2 px-md-5 ">
            <div className="FAQ">
              <div
                className="accordion"
                id="faqAccordion"
                data-bs-parent="#faqAccordion"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingSeven">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      <div className="d-md-flex">
                        <div className=" ps-md-4 faqanswertitle pt-3">
                          How do I apply for support or join a program offered
                          by WEWAD?{" "}
                        </div>
                      </div>
                    </button>
                  </h2>
                  <div
                    id="collapseSeven"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSeven"
                    data-bs-parent="#faqAccordion"
                  >
                    <div className="accordion-body pt-3 pb-4 ">
                      To apply for support or join one of our programs, you can
                      visit our website and fill out the application form in the
                      "Get Involved" section. Our team will review your
                      application and get in touch with you.{" "}
                    </div>
                  </div>
                </div>

                {/* ... other FAQ items with similar structure */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Home;
