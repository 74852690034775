import React from "react";
import "animate.css";
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"; // Import Animate.css styles
// import { Link } from "react-router-dom";
import homesection4img1 from "../images/homesection4img1.jfif";
import testimonialimg1 from "../images/testimonialimg1.png";
import testimonialimg2 from "../images/testimonialimg2.png";
import testimonialimg3 from "../images/testimonialimg3.png";
import testimonialimg4 from "../images/testimonialimg4.png";
import testimonialimg5 from "../images/testimonialimg5.png";
import testimonialimg6 from "../images/testimonialimg6.png";
import testimonialimg7 from "../images/testimonialimg7.png";
import testimonialimg8 from "../images/testimonialimg8.png";
import testimonialimg9 from "../images/testimonialimg9.png";
import testimonialbackgroundimg from "../images/testimonialbackgroundimg.jfif";
import blogimg1 from "../images/blogimg1.png";
import blogimg2 from "../images/blogimg2.png";
import blogimg3 from "../images/blogimg3.png";
import blogimg4 from "../images/blogimg4.png";
import blogimg5 from "../images/blogimg5.png";

function Blog() {
  return (
    <div className="p-md-5 p-3">
      <section className="aboutsection1">
        <div className="homesection3heading pb-2">
          Welcome to the WEWAD Blog{" "}
        </div>
        <div className="homesection3subheading">
          Discover how we are making a difference, learn from our community’s
          experiences, and gain valuable insights into the world of empowerment,
          inclusivity, and support for women and individuals with disabilities.
        </div>
        <div className="py-4">
          <div className="homesection2subgridgroup">
            <div>
              <img className="homesection4img" src={blogimg1} alt="" />
            </div>
            <div className="blogdate pt-4 p">August 3, 2024</div>
            <div className="homesection2subgridheading pt-3 pb-2">
              WEWAD Foundation Empowers a young lady with Beautician Accessories{" "}
            </div>
            <div className="pt-2 homesection2subgridsubheading homesection4subgridsubheading">
              The Women Em powering Women and Disabled (WEWAD) Foundation
              recently empowered Kafayat Ajoke Mohammed with a set of beautician
              accessories, fueling her passion for the beauty industry. Kafayat,
              brimming with gratitude, conveyed her heartfelt appreciation to
              the foundation for this transformative opportunity. Renowned for
              its dedication to fostering inclusivity and ...
            </div>
            <div className="pt-4 text-start">
              {" "}
              <button className="donatebtn">
                <a href="/wewad-foundation-empowers-a-young-lady-with-beautician-accessories/">
                  Read More <i class="ps-2 fa-solid fa-arrow-right"></i>
                </a>
              </button>{" "}
            </div>
          </div>
        </div>

        <div className="twocolumngrid blog2columngrid">
        <ScrollAnimation
            className=""
            animateIn="bounceInLeft"
            animationOut="fadeOut"
          >
          <div className="homesection2subgridgroup ">
            <div>
              <img className="homesectionimg py-3" src={blogimg2} alt="" />
            </div>
            <div className="blogdate">July 24, 2024</div>
            <div className="homesection2subgridheading pt-3 pb-2">
              The Art of Giving: More Than Just a Gesture
            </div>
            <div className="pt-2 homesection2subgridsubheading homesection4subgridsubheading">
              In a world that often emphasizes the importance of acquiring and
              achieving, the act of giving stands out as a powerful
              counterbalance. Giving, in its truest form, is not merely an
              action but an art that enriches both the giver and the receiver.
              Let’s delve into the essence of giving and explore its
              transformative power...
            </div>
            <div className="pt-4 text-start">
              {" "}
              <button className="donatebtn">
                {" "}
                <a href="/art-of-giving/">
                  Read More <i class="ps-2 fa-solid fa-arrow-right"></i>
                </a>
              </button>{" "}
            </div>
          </div>
          </ScrollAnimation>
          <ScrollAnimation
            className=""
            animateIn="bounceInRight"
            animationOut="fadeOut"
          >
          <div className="homesection2subgridgroup">
            <div>
              <img className="homesectionimg py-3" src={blogimg3} alt="" />
            </div>
            <div className="blogdate">July 12, 2024</div>
            <div className="homesection2subgridheading pt-3 pb-2">
              Becoming a World Changer: Steps to Make a Difference
            </div>
            <div className="pt-2 homesection2subgridsubheading homesection4subgridsubheading">
              In a world filled with challenges, becoming a world changer is a
              noble pursuit that many aspire to. Whether it’s tackling social
              issues, advancing technology, or fostering environmental
              sustainability, each person has the potential to make a meaningful
              impact. If you’re passionate about creating positive change but
              don’t know where to start, this blog post will guide you through
              the steps to becoming a world changer.
            </div>
            <div className="pt-4 text-start">
              {" "}
              <button className="donatebtn">
                {" "}
                <a href="/becoming-a-world-changer-steps-to-make-a-difference/">
                  Read More <i class="ps-2 fa-solid fa-arrow-right"></i>
                </a>
              </button>{" "}
            </div>
          </div>
          </ScrollAnimation>
          <ScrollAnimation
            className=""
            animateIn="bounceInLeft"
            animationOut="fadeOut"
          >
          <div className="homesection2subgridgroup">
            <div>
              <img className="homesectionimg py-3" src={blogimg4} alt="" />
            </div>
            <div className="blogdate">June 14, 2024</div>
            <div className="homesection2subgridheading pt-3 pb-2">
              The Ripple Effect of Giving: How Small Acts Can Create Big Change{" "}
            </div>
            <div className="pt-2 homesection2subgridsubheading homesection4subgridsubheading">
              Introduction Have you ever thrown a pebble into a pond and watched
              the ripples spread outwards, reaching every corner? Just like that
              pebble, acts of giving can create a ripple effect that touches
              lives far beyond the initial act. The beauty of giving lies not
              only in the immediate impact it has but also in the lasting legacy
              it leaves behind. In this blog post, we will explore the ripple
              effect of giving and how small acts can create significant change
              in our communities and beyond.
            </div>
            <div className="pt-4 text-start">
              {" "}
              <button className="donatebtn">
                {" "}
                <a href="/the-ripple-effect-of-giving-how-small-acts-can-create-big-change/">
                  Read More <i class="ps-2 fa-solid fa-arrow-right"></i>
                </a>
              </button>{" "}
            </div>
          </div>
          </ScrollAnimation>
          <ScrollAnimation
            className=""
            animateIn="bounceRight"
            animationOut="fadeOut"
          >
          <div className="homesection2subgridgroup">
            <div>
              <img className="homesectionimg py-3" src={blogimg5} alt="" />
            </div>
            <div className="blogdate">August 3, 2024</div>
            <div className="homesection2subgridheading pt-3 pb-2">
              Supporting People Around You: Why It Matters and How to Do It
            </div>
            <div className="pt-2 homesection2subgridsubheading homesection4subgridsubheading">
              Supporting the people around you isn’t just a kind gesture; it’s a
              fundamental part of building strong, resilient communities.
              Whether it’s your friends, family, colleagues, or neighbors,
              offering support can make a significant difference in someone’s
              life. In this blog post, we’ll explore why supporting others is
              important and provide practical tips on how you can be there for
              the people in your life.
            </div>
            <div className="pt-4 text-start">
              {" "}
              <button className="donatebtn">
                <a
                  href="/supporting-people-around-you-why-it-matters-and-how-to-do-it/"
                >
                  Read More <i class="ps-2 fa-solid fa-arrow-right"></i>
                </a>
              </button>{" "}
            </div>
          </div>
          </ScrollAnimation>
        </div>
      </section>
        <section className="homesection6 p-4 p-md-5">
        <div className="homesection3heading">What People Say About Us</div>
        <div className="homesection3subheading">
          Hear from those who have benefited from our programs
        </div>
        <div className="homesection6grid pt-3">
        <ScrollAnimation className="" animateIn="bounceInUp" animationOut="fadeOut">

          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg1} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Chidinma Okeke</div>
                <div className="testimoniallocation pt-1">Lagos, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD has been a beacon of hope for me. As a single mother, the
              vocational training I received has enabled me to start my own
              small business. Now, I can provide for my family and secure a
              brighter future for my children. Thank you, WEWAD!
            </div>
          </div>
          </ScrollAnimation>
          <ScrollAnimation className="" animateIn="bounceInUp" animationOut="fadeOut">

          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg2} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Tunde Adebayo</div>
                <div className="testimoniallocation pt-1">Ibadan, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              Living with a disability was challenging, but WEWAD's support has
              made a tremendous difference. The mentorship and resources
              provided have empowered me to live independently and pursue my
              career goals. I am forever grateful.
            </div>
          </div>
          </ScrollAnimation>
          <ScrollAnimation className="" animateIn="bounceInUp" animationOut="fadeOut">

          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg3} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Ngozi Okoro</div>
                <div className="testimoniallocation pt-1">Rivers, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              Thanks to WEWAD, I have found a community that supports and
              uplifts me. The programs have not only provided me with practical
              skills but also boosted my confidence. I am now running a
              successful catering business and giving back to my community.
            </div>
          </div>
          </ScrollAnimation>
          <ScrollAnimation className="" animateIn="bounceInUp" animationOut="fadeOut">

          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg4} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Emeka Nwosu</div>
                <div className="testimoniallocation pt-1">Enugu, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD's empowerment programs have transformed my life. I have
              learned new skills and met wonderful people who inspire me daily.
              With WEWAD's help, I have started a tailoring business, and I am
              proud of what I have achieved.!
            </div>
          </div>
          </ScrollAnimation>
          <ScrollAnimation className="" animateIn="bounceInUp" animationOut="fadeOut">

          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg5} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Kemi Adesina</div>
                <div className="testimoniallocation pt-1">
                  Port Harcourt, Nigeria.
                </div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              The inclusive community at WEWAD has been a source of strength for
              me. The resources and guidance I received have helped me build a
              successful career in technology. WEWAD truly changes lives, and I
              am a testament to that.
            </div>
          </div>
          </ScrollAnimation>
          <ScrollAnimation className="" animateIn="bounceInUp" animationOut="fadeOut">

          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg6} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Femi Adeoye</div>
                <div className="testimoniallocation pt-1">
                  Benin City, Nigeria.
                </div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD's initiatives are life-changing. The vocational training I
              received has opened up new opportunities for me, and I am now
              working in a field I love. WEWAD’s commitment to empowerment is
              evident in everything they do.
            </div>
          </div>
          </ScrollAnimation>
             <ScrollAnimation className="" animateIn="bounceInUp" animationOut="fadeOut">

          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg7} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Aisha Bello</div>
                <div className="testimoniallocation pt-1">Abuja, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              Joining WEWAD was one of the best decisions I’ve ever made. The
              community is incredibly supportive, and the workshops have given
              me valuable skills and confidence. I now feel equipped to face any
              challenge that comes my way.
            </div>
          </div>
          </ScrollAnimation>
          <ScrollAnimation className="" animateIn="bounceInUp" animationOut="fadeOut">

          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg8} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Yemi Oladipo</div>
                <div className="testimoniallocation pt-1">Jos, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              The mentorship and support from WEWAD have been invaluable. I have
              gained skills that have made me more competitive in the job
              market. WEWAD’s focus on inclusivity and empowerment is making a
              real difference in people’s lives.
            </div>
          </div>
          </ScrollAnimation>
          <ScrollAnimation className="" animateIn="bounceInUp" animationOut="fadeOut">

          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg9} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Funke Afolayan</div>
                <div className="testimoniallocation pt-1">Ilorin, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD has given me hope and a sense of purpose. The workshops and
              training sessions have equipped me with the tools I need to
              succeed. I am now confidently pursuing my passion for fashion
              design, and I owe it all to WEWAD.
            </div>
          </div>
          </ScrollAnimation>
        </div>
      </section>
    </div>
  );
}
export default Blog;
