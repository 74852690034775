
import './App.css';
import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Navbar from './components/navbar';
import Footer from './components/footer';
import Home from './pages/home';
import About from './pages/about';
import Blog from './pages/blog';
import Donate from './pages/donate';
import BlogPage1 from './pages/blogpage1';
import BlogPage2 from './pages/blogpage2';
import BlogPage3 from './pages/blogpage3';
import BlogPage4 from './pages/blogpage4';
import BlogPage5 from './pages/blogpage5';
import Contact from './pages/contact';
import PhotoGallery from './pages/photogallery';
import VideoGallery from './pages/videogallery';





 

 

function App() {
  return (
    <BrowserRouter>
      <div className='app'>
            
            <Navbar/>
           
      <Routes>
        <Route path="/" exact element={<Home />}/>
        <Route path="/about"  element={<About />}/>
        <Route path="/blog" element={<Blog />}/>
        <Route path="/donate" element={<Donate />}/>
        <Route path="/photogallery" element={<PhotoGallery />}/>
        <Route path="/videogallery" element={<VideoGallery/>}/>
        <Route path="/wewad-foundation-empowers-a-young-lady-with-beautician-accessories/"  element={<BlogPage1/>}/> 
        <Route path="/art-of-giving/"  element={<BlogPage2/>}/> 
        <Route path="/becoming-a-world-changer-steps-to-make-a-difference/"  element={<BlogPage3/>}/> 
        <Route path="/the-ripple-effect-of-giving-how-small-acts-can-create-big-change/"  element={<BlogPage4/>}/> 
        <Route path="/supporting-people-around-you-why-it-matters-and-how-to-do-it/"  element={<BlogPage5/>}/> 

        <Route path="/contact" element={<Contact />}/>
           </Routes>
      <Footer/>
      </div>
      </BrowserRouter>
    

  );
}

export default App;
