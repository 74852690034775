import React from "react";
import "animate.css";
// import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"; // Import Animate.css styles
// import { Link } from "react-router-dom";
import homesection4img1 from "../images/homesection4img1.jfif";

import testimonialimg1 from "../images/testimonialimg1.png";
import testimonialimg2 from "../images/testimonialimg2.png";
import testimonialimg3 from "../images/testimonialimg3.png";
import testimonialimg4 from "../images/testimonialimg4.png";
import testimonialimg5 from "../images/testimonialimg5.png";
import testimonialimg6 from "../images/testimonialimg6.png";
import testimonialimg7 from "../images/testimonialimg7.png";
import testimonialimg8 from "../images/testimonialimg8.png";
import testimonialimg9 from "../images/testimonialimg9.png";
import testimonialbackgroundimg from "../images/testimonialbackgroundimg.jfif";
import blogimg3 from "../images/blogimg3.png";
import blogpageimg2 from "../images/blogimg3.png";
import blogpageimg3 from "../images/blogpageimg3.jpg";
import blogpageimg4 from "../images/blogpageimg4.jpg";
import blogpageimg5 from "../images/blogpageimg5.jpg";
import blogpageimg6 from "../images/blogpageimg6.jpg";

function BlogPage3() {
  return (
    <div className="p-md-5 p-3">
      <div className="wh">
        <section className="aboutsection1 py-md-5 pb-2 pt-5">
          <div className="homesection3heading pb-2">
            Becoming a World Changer: Steps to Make a Difference
          </div>
          <div className="py-3 text-start">
            <img src={blogimg3} alt="" className="text-start mobilewidth" />
          </div>
          <div className="blogpagepara py-2">
            In a world filled with challenges, becoming a world changer is a
            noble pursuit that many aspire to. Whether it’s tackling social
            issues, advancing technology, or fostering environmental
            sustainability, each person has the potential to make a meaningful
            impact. If you’re passionate about creating positive change but
            don’t know where to start, this blog post will guide you through the
            steps to becoming a world changer.
          </div>
          <div className="blogpagesubheading pt-4 pb-2">
            Step 1: Identify Your Passion
          </div>
          <div className="blogpagepara pt-2">
            The first step in becoming a world changer is identifying what
            you’re passionate about. What issues or causes resonate with you on
            a deep level? Whether it’s education, healthcare, poverty
            alleviation, or environmental conservation, finding your passion
            will fuel your journey towards making a difference.
          </div>
          <div className="blogpagesubheading pt-4 pb-2">
            Step 2: Educate Yourself
          </div>
          <div className="blogpagepara pt-2">
            Once you’ve identified your passion, the next step is to educate
            yourself about the issue. Read books, articles, and research papers
            to gain a deeper understanding of the challenges and potential
            solutions. Attend workshops, seminars, and conferences to learn from
            experts and connect with like-minded individuals who share your
            passion.
          </div>
          <div className="blogpagesubheading pt-4 pb-2">
            Step 3: Set Clear Goals
          </div>
          <div className="blogpagepara pt-2">
            After educating yourself, set clear and achievable goals for what
            you want to accomplish. Your goals should be specific, measurable,
            attainable, relevant, and time-bound (SMART). For example, if you’re
            passionate about improving access to education, your goal could be
            to establish a scholarship program for underprivileged students
            within the next year.
          </div>
          <div className="blogpagesubheading pt-4 pb-2">
            Step 4: Take Action
          </div>
          <div className="blogpagepara pt-2">
            With your goals in place, it’s time to take action. Start small by
            volunteering with local organizations, participating in community
            events, or launching a grassroots campaign to raise awareness about
            your chosen cause. As you gain experience and build momentum, you
            can scale up your efforts and collaborate with others to make an
            even bigger impact.
          </div>
          <div className="blogpagesubheading pt-4 pb-2">
            Step 5: Be Persistent and Resilient
          </div>
          <div className="blogpagepara pt-2">
            Becoming a world changer is not always easy, and you’re likely to
            face obstacles and setbacks along the way. It’s important to remain
            persistent and resilient, staying committed to your goals even when
            faced with challenges. Remember, every setback is an opportunity to
            learn and grow stronger in your pursuit of making a difference.
          </div>{" "}
          <div className="blogpagesubheading pt-4 pb-2">
            Step 6: Inspire Others
          </div>
          <div className="blogpagepara pt-2">
            As you make progress towards your goals, don’t forget to inspire
            others to join you on your journey. Share your story, celebrate your
            successes, and encourage others to get involved and contribute to
            the cause. Together, we can create a ripple effect of positive
            change that transforms our world for the better.
          </div>{" "}
          <div className="blogpagesubheading pt-4 pb-2">Conclusion</div>
          <div className="blogpagepara pt-2">
            Becoming a world changer is a journey that begins with identifying
            your passion and taking meaningful action to create positive change.
            By educating yourself, setting clear goals, taking action, being
            persistent and resilient, and inspiring others, you can make a
            lasting impact on the world around you. Remember, it’s not about how
            much you do, but the heart and passion you put into making a
            difference. So, what are you waiting for? Start your journey to
            becoming a world changer today!
          </div>{" "}
          
        </section>
      </div>
      <section className="homesection6 p-md-5">
        <div className="homesection3heading">What People Say About Us</div>
        <div className="homesection3subheading">
          Hear from those who have benefited from our programs
        </div>
        <div className="homesection6grid pt-3">
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg1} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Chidinma Okeke</div>
                <div className="testimoniallocation pt-1">Lagos, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD has been a beacon of hope for me. As a single mother, the
              vocational training I received has enabled me to start my own
              small business. Now, I can provide for my family and secure a
              brighter future for my children. Thank you, WEWAD!
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg2} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Tunde Adebayo</div>
                <div className="testimoniallocation pt-1">Ibadan, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              Living with a disability was challenging, but WEWAD's support has
              made a tremendous difference. The mentorship and resources
              provided have empowered me to live independently and pursue my
              career goals. I am forever grateful.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg3} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Ngozi Okoro</div>
                <div className="testimoniallocation pt-1">Rivers, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              Thanks to WEWAD, I have found a community that supports and
              uplifts me. The programs have not only provided me with practical
              skills but also boosted my confidence. I am now running a
              successful catering business and giving back to my community.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg4} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Emeka Nwosu</div>
                <div className="testimoniallocation pt-1">Enugu, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD's empowerment programs have transformed my life. I have
              learned new skills and met wonderful people who inspire me daily.
              With WEWAD's help, I have started a tailoring business, and I am
              proud of what I have achieved.!
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg5} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Kemi Adesina</div>
                <div className="testimoniallocation pt-1">
                  Port Harcourt, Nigeria.
                </div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              The inclusive community at WEWAD has been a source of strength for
              me. The resources and guidance I received have helped me build a
              successful career in technology. WEWAD truly changes lives, and I
              am a testament to that.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg6} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Femi Adeoye</div>
                <div className="testimoniallocation pt-1">
                  Benin City, Nigeria.
                </div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD's initiatives are life-changing. The vocational training I
              received has opened up new opportunities for me, and I am now
              working in a field I love. WEWAD’s commitment to empowerment is
              evident in everything they do.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg7} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Aisha Bello</div>
                <div className="testimoniallocation pt-1">Abuja, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              Joining WEWAD was one of the best decisions I’ve ever made. The
              community is incredibly supportive, and the workshops have given
              me valuable skills and confidence. I now feel equipped to face any
              challenge that comes my way.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg8} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Yemi Oladipo</div>
                <div className="testimoniallocation pt-1">Jos, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              The mentorship and support from WEWAD have been invaluable. I have
              gained skills that have made me more competitive in the job
              market. WEWAD’s focus on inclusivity and empowerment is making a
              real difference in people’s lives.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg9} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Funke Afolayan</div>
                <div className="testimoniallocation pt-1">Ilorin, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD has given me hope and a sense of purpose. The workshops and
              training sessions have equipped me with the tools I need to
              succeed. I am now confidently pursuing my passion for fashion
              design, and I owe it all to WEWAD.
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default BlogPage3;
