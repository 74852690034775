import React from "react";
import "animate.css";
// import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.compat.css"; // Import Animate.css styles
// import { Link } from "react-router-dom";
import homesection4img1 from "../images/homesection4img1.jfif";

import testimonialimg1 from "../images/testimonialimg1.png";
import testimonialimg2 from "../images/testimonialimg2.png";
import testimonialimg3 from "../images/testimonialimg3.png";
import testimonialimg4 from "../images/testimonialimg4.png";
import testimonialimg5 from "../images/testimonialimg5.png";
import testimonialimg6 from "../images/testimonialimg6.png";
import testimonialimg7 from "../images/testimonialimg7.png";
import testimonialimg8 from "../images/testimonialimg8.png";
import testimonialimg9 from "../images/testimonialimg9.png";
import testimonialbackgroundimg from "../images/testimonialbackgroundimg.jfif";
import blogimg4 from "../images/blogimg4.png";

function BlogPage4() {
  return (
    <div className="p-md-5 p-4">
      <div className="wh">
        <section className="aboutsection1 py-4">
          <div className="homesection3heading  pt-4 pb-2">
            The Ripple Effect of Giving: <br />How Small Acts Can Create Big Change{" "}
          </div>
          <div className="py-3 ">
            <img src={blogimg4} alt="" className="mobilewidth"/>
          </div>
          <div className="blogpagesubheading pt-4 pb-1">Introduction</div>
          <div className="blogpagepara pt-2">
            Have you ever thrown a pebble into a pond and watched the ripples
            spread outwards, reaching every corner? Just like that pebble, acts
            of giving can create a ripple effect that touches lives far beyond
            the initial act. The beauty of giving lies not only in the immediate
            impact it has but also in the lasting legacy it leaves behind. In
            this blog post, we will explore the ripple effect of giving and how
            small acts can create significant change in our communities and
            beyond.
          </div>
          <div className="blogpagesubheading pt-4 pb-1">
            The Power of Giving
          </div>
          <div className="blogpagepara pt-2">
            Giving is a powerful act that transcends boundaries and brings
            people together. Whether it’s donating money to a charitable cause,
            volunteering your time at a local shelter, or simply lending a
            helping hand to someone in need, giving has the potential to
            transform lives. It’s not just about the material or financial
            assistance provided; it’s about the emotional support,
            encouragement, and hope that comes with it.
          </div>
          <div className="blogpagesubheading pt-4 pb-1">
            How Giving Creates Ripples
          </div>
          <div className="blogpagesubheadingsmall pt-2">
            1. Inspiring Others
          </div>
          <div className="blogpagepara pt-2">
            The first step in becoming a world changer is identifying what
            you’re passionate about. What issues or causes resonate with you on
            a deep level? Whether it’s education, healthcare, poverty
            alleviation, or environmental conservation, finding your passion
            will fuel your journey towards making a difference.
          </div>
          <div className="blogpagesubheadingsmall pt-3">
            2. Strengthening Communities
          </div>
          <div className="blogpagepara pt-2">
            Acts of giving help build stronger, more resilient communities. By
            supporting local initiatives and organizations, you contribute to
            the overall well-being of your community, fostering a sense of unity
            and collaboration among its members.
          </div>
          <div className="blogpagesubheadingsmall pt-3">
            3. Multiplying Impact
          </div>
          <div className="blogpagepara pt-2">
            The impact of giving is often multiplied over time. What starts as a
            small gesture can lead to bigger initiatives, partnerships, and
            collaborations that amplify the positive effects of your initial
            act. This exponential growth is the essence of the ripple effect,
            demonstrating the transformative power of collective action.
          </div>
          <div className="blogpagesubheading pt-4 pb-1">Introduction</div>
          <div className="blogpagepara pt-2">
            Have you ever thrown a pebble into a pond and watched the ripples
            spread outwards, reaching every corner? Just like that pebble, acts
            of giving can create a ripple effect that touches lives far beyond
            the initial act. The beauty of giving lies not only in the immediate
            impact it has but also in the lasting legacy it leaves behind. In
            this blog post, we will explore the ripple effect of giving and how
            small acts can create significant change in our communities and
            beyond.
          </div>
          <div className="blogpagesubheading pt-4 pb-1">Real-Life Examples</div>

          <div className="blogpagesubheadingsmall pt-3">
            1. Community Gardens
          </div>
          <div className="blogpagepara pt-2">
            Imagine a neighborhood coming together to create a community garden.
            What starts as a small plot of land can grow into a thriving
            community hub where residents gather, share resources, and learn
            from one another. The garden not only provides fresh produce but
            also fosters connections and promotes sustainable living.
          </div>
          <div className="blogpagesubheadingsmall pt-3">
            2. Educational Scholarships
          </div>
          <div className="blogpagepara pt-2">
            A scholarship fund established by a generous donor can change the
            trajectory of a student’s life. By removing financial barriers to
            education, the scholarship empowers students to pursue their dreams
            and contribute to society in meaningful ways. Over time, these
            educated individuals become leaders and changemakers, inspiring
            future generations to do the same.
          </div>
          <div className="blogpagesubheadingsmall pt-3">
            3. Random Acts of Kindness
          </div>
          <div className="blogpagepara pt-2">
            Simple acts of kindness, such as paying for someone’s coffee or
            helping a stranger in need, can have a profound impact on someone’s
            day. These random acts of kindness create a ripple effect of
            positivity, encouraging others to spread kindness and compassion
            wherever they go.
          </div>
          <div className="blogpagesubheading pt-4 pb-1">Conclusion
          </div>

          <div className="blogpagepara pt-2">
            The ripple effect of giving is a powerful phenomenon that
            demonstrates the interconnectedness of our actions and their
            far-reaching consequences. Whether big or small, every act of giving
            has the potential to create positive change and leave a lasting
            legacy. So, the next time you have the opportunity to give, remember
            the ripple effect you can create and embrace the power of generosity
            to make a difference in the world. After all, it’s not just about
            the act of giving but also about the lives you touch and the
            communities you help build along the way.
          </div>
        </section>
      </div>
      <section className="homesection6 p-md-5">
        <div className="homesection3heading">What People Say About Us</div>
        <div className="homesection3subheading">
          Hear from those who have benefited from our programs
        </div>
        <div className="homesection6grid pt-3">
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg1} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Chidinma Okeke</div>
                <div className="testimoniallocation pt-1">Lagos, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD has been a beacon of hope for me. As a single mother, the
              vocational training I received has enabled me to start my own
              small business. Now, I can provide for my family and secure a
              brighter future for my children. Thank you, WEWAD!
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg2} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Tunde Adebayo</div>
                <div className="testimoniallocation pt-1">Ibadan, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              Living with a disability was challenging, but WEWAD's support has
              made a tremendous difference. The mentorship and resources
              provided have empowered me to live independently and pursue my
              career goals. I am forever grateful.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg3} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Ngozi Okoro</div>
                <div className="testimoniallocation pt-1">Rivers, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              Thanks to WEWAD, I have found a community that supports and
              uplifts me. The programs have not only provided me with practical
              skills but also boosted my confidence. I am now running a
              successful catering business and giving back to my community.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg4} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Emeka Nwosu</div>
                <div className="testimoniallocation pt-1">Enugu, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD's empowerment programs have transformed my life. I have
              learned new skills and met wonderful people who inspire me daily.
              With WEWAD's help, I have started a tailoring business, and I am
              proud of what I have achieved.!
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg5} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Kemi Adesina</div>
                <div className="testimoniallocation pt-1">
                  Port Harcourt, Nigeria.
                </div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              The inclusive community at WEWAD has been a source of strength for
              me. The resources and guidance I received have helped me build a
              successful career in technology. WEWAD truly changes lives, and I
              am a testament to that.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg6} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Femi Adeoye</div>
                <div className="testimoniallocation pt-1">
                  Benin City, Nigeria.
                </div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD's initiatives are life-changing. The vocational training I
              received has opened up new opportunities for me, and I am now
              working in a field I love. WEWAD’s commitment to empowerment is
              evident in everything they do.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg7} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Aisha Bello</div>
                <div className="testimoniallocation pt-1">Abuja, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              Joining WEWAD was one of the best decisions I’ve ever made. The
              community is incredibly supportive, and the workshops have given
              me valuable skills and confidence. I now feel equipped to face any
              challenge that comes my way.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg8} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Yemi Oladipo</div>
                <div className="testimoniallocation pt-1">Jos, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              The mentorship and support from WEWAD have been invaluable. I have
              gained skills that have made me more competitive in the job
              market. WEWAD’s focus on inclusivity and empowerment is making a
              real difference in people’s lives.
            </div>
          </div>
          <div
            className="testimonialgridbox p-3"
            style={{
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${testimonialbackgroundimg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="d-flex">
              <div className="pe-2">
                <img src={testimonialimg9} alt="" />
              </div>
              <div className="pt-3 ">
                <div className="testimonialname">Funke Afolayan</div>
                <div className="testimoniallocation pt-1">Ilorin, Nigeria.</div>
              </div>
            </div>
            <div className="testimonialpara pt-2">
              WEWAD has given me hope and a sense of purpose. The workshops and
              training sessions have equipped me with the tools I need to
              succeed. I am now confidently pursuing my passion for fashion
              design, and I owe it all to WEWAD.
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default BlogPage4;
